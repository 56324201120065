import appGlobals from "./globals";
import App from "./App";
import ProductListContent from "./Product/ProductListContent";
import HeaderCart from "./Cart/HeaderCart";
import CartList from "./Cart/CartList";
import HeaderCartLastProductBox from "./Cart/HeaderCartLastProductBox";
import CartContent from "./Cart/CartContent";
import api from "./api";
import Scrollbar from 'smooth-scrollbar';
import slick from "slick-carousel";
import Choices from 'choices.js';
import arrowSrc from './assets/i/arrow.png';
import arrowCarouselSrc from './assets/i/arrow-carousel.png';
import logoSrc from './assets/i/logo.png';
import iineHandSrc from './assets/i/hand_white.png';
import waypoint from 'waypoints/lib/jquery.waypoints.min';

import ProductDetailContent from "./Product/ProductDetailContent";
import WidgetCarousel from "./Widget/WidgetCarousel";
import basketIconSrc from './assets/i/icon-basket.png';
import Preloader from "./Common/Preloader";
import CheckoutInputInfo from "./Checkout/CheckoutInputInfo";
import appConfig from "./config";
import minusIconSrc from "./assets/i/icon-minus.png";
import Toast from "./Common/Toast";
import marketPlaceAnalytics from "./analytics";
import {IineFingerprint} from "./assets/libs/fingerprint";
import CookieConsent from "./Common/CookieConsent";
import MerchantListSummary from "./Cart/Merchants/Summary/MerchantListSummary";
import {v4 as uuidv4} from 'uuid';
import PinchZoom from 'pinch-zoom-js';

var RegexParser = require("regex-parser");


const tools = {
    init: function () {
        // document.addEventListener('DOMContentLoaded', function () {
        let websiteId = document.getElementById('iine-marketplace-script');
        if (!websiteId) {
            return false;
        }

        //finger print
        const userFingerPrint = new IineFingerprint();
        userFingerPrint._createFingerprint();


        document.querySelector('html').classList.add('iine--marketplace--app');
        appConfig.websiteId = parseInt(websiteId.getAttribute('data-website-id'));
        tools.setupPreviewCarousel();
        tools.setup();
        tools.modalEventsListeners();
        // }, false);
    },

    setup: () => {
        const applicationDiv = document.createElement('div');
        applicationDiv.id = appGlobals.appId;
        applicationDiv.style.display = 'none';

        document.body.appendChild(applicationDiv);
        window.document.getElementById(appGlobals.appId).replaceWith(App);
        tools.appendMarketPlaceStylesTag(appGlobals.marketPlaceFrontStyles, appConfig.scriptDomainUrl + '/dist/css/front.css');
        tools.appendMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles, appConfig.scriptDomainUrl + '/dist/css/main.css');

        setTimeout(() => {
            tools.setupHTMLXPath();
            let isGuid = helpers.getUserGuid();
            if (!isGuid) {
                helpers.setUserGuid();
            }
        }, 150)
    },

    setupHTMLXPath: () => {
        if (!appGlobals.blockApiCalls) {
            tools.setupProductList();
            tools.setupCheckoutData();

            setTimeout(() => {
                $('.iineCarouselShadowDOM, .iineWidgetHotspot, .iineWidgetText').waypoint(function () {
                    let el = $(this.element);
                    let widgetId = el.attr('widget-id');

                    if (!marketPlaceAnalytics.alreadySawWidgets[widgetId]) {
                        marketPlaceAnalytics.alreadySawWidgets[widgetId] = true;
                        /**
                         * Call analytics events / Quantity change
                         */
                        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetImpression, {widgetId: widgetId}, helpers.getProductsByWidgetId(widgetId));
                    } else {
                        /**
                         * Call analytics events / Quantity change
                         */
                        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetRenewedImpression, {widgetId: widgetId}, helpers.getProductsByWidgetId(widgetId));
                        delete marketPlaceAnalytics.alreadySawWidgets[widgetId];
                    }
                });

                tools.handleAmpCart();
            }, 500)


            // let waypoint = new Waypoint({
            //     element: document.getElementById('myWaypoint'),
            //     handler: function(direction) {
            //         console.log('Scrolled to waypoint!')
            //     }
            // })
        }
    },

    setupClassEvents: (className, functionName) => {
        let elements = document.querySelectorAll(className);
        elements.forEach(element => {
            element.addEventListener('click', functionName);
        })
    },

    setupPreviewCarousel: () => {
        let previewCarouselElement = document.querySelector('[data-iine-carousel-preview]');
        if (previewCarouselElement) {
            let previewCarouselData = previewCarouselElement.getAttribute('data-iine-carousel-preview');
            if (previewCarouselData) {
                previewCarouselData = JSON.parse(previewCarouselData);
            }
            appGlobals.blockApiCalls = true;
            appGlobals.previewCarouselProducts = previewCarouselData;


            let isHTMLElement = previewCarouselData.element.startsWith('data-');
            let widget = previewCarouselData;

            let index = 1;
            tools.createCarouselWidget(isHTMLElement, widget, index, true);
        }
    },


    setupProductList: async () => {
        const products = await api.getProductsList(appConfig.websiteId);
        if (!products) {
            return false;
        }
        appGlobals.widgets = products;
        if (!products.widgets) {
            return false;
        }

        const recalculateAnchorPointsPosition = () => {
            document.querySelectorAll('.iineWidgetHotspot').forEach(element => {
                element.remove();
            });

            products.widgets.map((widget, index) => {
                if (widget.pluginType == appGlobals.widgetTypeIinePopup || widget.pluginType == appGlobals.widgetTypeIineAnchor) {
                    if (widget.element) {
                        let widgetProducts = widget.products;
                        let isHTMLElement = widget.element.startsWith('data-');
                        createAnchorPoints(isHTMLElement, widget, widgetProducts, index, true);
                    }
                }
            })
        };

        const wrap = (el, wrapper) => {
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);
        }

        const recalculatePositions = (products, element, widgetIndex, setVisible = false, createWrapper = false) => {
            if (createWrapper) {
                if (!element.closest('.marketplaceImageWrapper')) {
                    let div = document.createElement('div');
                    div.classList.add('marketplaceImageWrapper');
                    div.style.position = 'relative';
                    wrap(element, div);
                }
            }
            products.map(product => {
                let hotspot = document.createElement('div');
                hotspot.classList.add('hotspot', 'iineWidgetHotspot');
                setVisible ? hotspot.style.display = 'block' : hotspot.style.display = 'none';

                let elementPosition = helpers.getCoords(element);
                let diffX = (element.width * product.position.x) / 100;
                let diffY = (element.height * product.position.y) / 100;
                let hotspotX = (diffX + elementPosition.left).toFixed(0);
                let hotspotY = (diffY + elementPosition.top).toFixed(0);

                hotspot.style.left = hotspotX + 'px';
                hotspot.style.top = hotspotY + 'px';

                hotspot.addEventListener('click', (event) => {
                    event.preventDefault();
                    appGlobals.activeWidgetIndex = widgetIndex;
                    let productDetails = api.getProduct(product.id);
                    productDetails.then(response => {
                        tools.setupProductLayer(response);
                    });
                    return false;
                });

                hotspot.setAttribute('widget-id', appGlobals.widgets.widgets[widgetIndex].element);


                hotspot.addEventListener('mouseenter', event => {
                    /**
                     * Call analytics events
                     */
                    //marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetHover, {widgetId: appGlobals.widgets.widgets[widgetIndex].element}, [product]);
                })

                if (createWrapper) {
                    hotspot.style.left = product.position.x + '%';
                    hotspot.style.top = product.position.y + '%';
                    hotspot.style.position = 'absolute';
                    element.closest('.marketplaceImageWrapper').append(hotspot);
                } else {
                    document.body.append(hotspot);
                }
            })
        };

        const recalculatePositionHotspot = (element, widgetIndex, setVisible = false, createWrapper = false) => {
            if (createWrapper) {
                if (!element.closest('.marketplaceImageWrapper')) {
                    let div = document.createElement('div');
                    div.classList.add('marketplaceImageWrapper');
                    div.style.position = 'relative';
                    wrap(element, div);
                }
            }

            let hotspot = document.createElement('div');
            let img = document.createElement('img');
            img.src = appConfig.scriptDomainUrl + iineHandSrc;

            let elementPosition = helpers.getCoords(element);
            let hotspotX = (elementPosition.left + 10).toFixed(0);
            let hotspotY = (elementPosition.top + 10).toFixed(0);

            setVisible ? hotspot.style.display = 'block' : hotspot.style.display = 'none';

            hotspot.style.top = hotspotY + 'px';
            hotspot.style.left = hotspotX + 'px';

            hotspot.classList.add('triggerWidgetPopupHotspot', 'iine-popup-hotspot', 'iineWidgetHotspot');
            hotspot.append(img);
            hotspot.setAttribute('widget-id', appGlobals.widgets.widgets[widgetIndex].element);

            hotspot.addEventListener('click', (event) => {
                event.preventDefault();
                appGlobals.activeWidgetIndex = widgetIndex;
                customModal.show();
                tools.setActiveProductsListLayer();
                tools.initFlexibleContent();
                // return false;
            });


            if (createWrapper) {
                hotspot.style.left = '10px';
                hotspot.style.top = '10px';
                hotspot.style.position = 'absolute';
                element.closest('.marketplaceImageWrapper').append(hotspot);
            } else {
                document.body.append(hotspot);
            }
        };


        function appendAnchorPoints(widgetProducts, element, widgetIndex, setVisible = false, createWrapper = false) {
            let hasHotspot = false;
            widgetProducts.map(product => {
                if (!product.position || (product.position && product.position.x === 0 && product.position.y === 0)) {
                    hasHotspot = true;
                }
            });

            if (hasHotspot) {
                recalculatePositionHotspot(element, widgetIndex, setVisible, createWrapper);
            } else {
                recalculatePositions(widgetProducts, element, widgetIndex, setVisible, createWrapper);
            }

        }

        function appendBasketItem(element, basketAlign) {
            let cartItem = document.createElement('div');
            let basketWrapper = document.createElement('span');
            let basketImage = document.createElement('img');
            basketImage.src = appConfig.scriptDomainUrl + basketIconSrc;
            basketImage.classList.add('front-iine-basket-image');

            let spanCounter = document.createElement('span');
            spanCounter.classList.add('cartLengthNumber');
            cartItem.classList.add('triggerCartWidgetPopup', 'mt-3', 'd-flex', 'justify-content-center', 'align-items-center');

            basketWrapper.append(basketImage);
            basketWrapper.append(spanCounter);
            basketWrapper.classList.add('basketWrapper');

            const switcher = document.createElement('span');
            switcher.classList.add('switcher--component', 'iineBasketSwitcher', '-opened');


            cartItem.append(basketWrapper);
            cartItem.append(switcher);

            if (element.parentElement && element.parentElement.nodeName == 'A') {
                element.parentElement.classList.add('triggerCartWidgetPopup');
            }

            basketAlign = basketAlign.toLowerCase();
            if (basketAlign === 'replace') {
                element.innerHTML = '';
                element.append(cartItem);
            } else if (basketAlign === 'right') {
                element.after(cartItem);
            } else if (basketAlign === 'left') {
                element.before(cartItem);
            }


            switcher.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                let switcher = e.currentTarget;
                if (switcher.classList.contains('-opened')) {
                    // switcher.classList.remove('-opened');
                    document.querySelectorAll('.basketWrapper').forEach(element => {
                        element.style.opacity = 0.6;
                    });

                    document.querySelectorAll('.iineWidgetCarousel, .iineWidgetHotspot').forEach(element => {
                        element.style.display = 'none';
                    });

                    document.querySelectorAll('.iineCarouselShadowDOM').forEach(element => {
                        element.style.display = 'none';
                        
                    });

                    document.querySelectorAll('.iineWidgetText').forEach(element => {
                        element.classList.add('forceStop');
                        element.querySelectorAll('u').forEach(uTag => {
                            uTag.style.textDecoration = 'none';
                        })
                    })

                    document.querySelectorAll('.iineBasketSwitcher').forEach(element => {
                        element.classList.remove('-opened')
                    })

                    /**
                     * Call analytics events
                     */
                    marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetsVisibilityToggled, {widgetsVisible: false}, []);

                } else {
                    // switcher.classList.add('-opened');
                    document.querySelectorAll('.basketWrapper').forEach(element => {
                        element.style.opacity = 1;
                    });
                    document.querySelectorAll('.iineWidgetCarousel, .iineWidgetHotspot').forEach(element => {
                        element.style.display = 'block';
                    });

                    document.querySelectorAll('.iineCarouselShadowDOM').forEach(element => {
                        element.style.display = 'block';
                        
                    });

                    document.querySelectorAll('.iineWidgetText').forEach(element => {
                        element.classList.remove('forceStop');
                        element.querySelectorAll('u').forEach(uTag => {
                            uTag.style.textDecoration = 'underline';
                        })
                    })

                    document.querySelectorAll('.iineBasketSwitcher').forEach(element => {
                        element.classList.add('-opened')
                    })

                    /**
                     * Call analytics events
                     */
                    marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetsVisibilityToggled, {widgetsVisible: true}, []);
                }
            });


            tools.setupClassEvents('.triggerCartWidgetPopup', (e) => {
                e.preventDefault();
                e.stopPropagation();
                customModal.show();
                tools.initFlexibleContent();
                const cartLayer = CartContent();
                tools.setPopupLayer('cartLayer', cartLayer);
                /**
                 * Call analytics events
                 */
                marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ChartOpened, {}, []);
            });

            // tools.setupClassEvents('.iineBasketSwitcher',(e) =>{
            //     e.preventDefault();
            //     e.stopPropagation();
            //     console.log(e.currentTarget);;
            //     let switcher = e.currentTarget;
            //     if(switcher.classList.contains('-opened')){
            //         switcher.classList.remove('-opened');
            //         document.querySelectorAll('.basketWrapper').forEach(element => {
            //             element.style.opacity = 0.6;
            //         });
            //
            //         document.querySelectorAll('.iineWidgetCarousel, .iineWidgetHotspot').forEach(element =>{
            //             element.style.display = 'none';
            //         });
            //
            //         document.querySelectorAll('.iineWidgetText').forEach(element => {
            //             element.classList.add('forceStop');
            //             element.querySelectorAll('u').forEach(uTag => {
            //                 uTag.style.textDecoration = 'none';
            //             })
            //         })
            //
            //     }else{
            //         switcher.classList.add('-opened');
            //         document.querySelectorAll('.basketWrapper').forEach(element => {
            //             element.style.opacity = 1;
            //         });
            //         document.querySelectorAll('.iineWidgetCarousel, .iineWidgetHotspot').forEach(element =>{
            //             element.style.display = 'block';
            //         });
            //
            //         document.querySelectorAll('.iineWidgetText').forEach(element => {
            //             element.classList.remove('forceStop');
            //             element.querySelectorAll('u').forEach(uTag => {
            //                 uTag.style.textDecoration = 'underline';
            //             })
            //         })
            //     }
            //
            // })
        }

        /**
         * Basket
         */
        let isBasketHTMLElement = products.basket && products.basket.element ? products.basket.element.includes('data-') : null
        if (isBasketHTMLElement) {
            let cartElements = document.querySelectorAll('[' + products.basket.element + ']');
            cartElements.forEach(element => {
                appendBasketItem(element, products.basket.align)
            })
        } else {
            let cartElements = tools.getElementByXpath(products.basket.element);
            for (let i = 0; i < cartElements.snapshotLength; i++) {
                let snapshotItem = cartElements.snapshotItem(i);
                appendBasketItem(snapshotItem, products.basket.align);
            }
        }


        const createAnchorPoints = (isHTMLElement, widget, widgetProducts, index, setVisible = false) => {
            // widget.config = {
            //     wrapElement: true
            // };
            let wrapElement = false;
            if ('config' in widget) {
                if (widget.config.wrapElement == true) {
                    wrapElement = true;
                }
            }

            if (isHTMLElement) {
                let xPathImageElements = document.querySelectorAll('[' + widget.element + ']');
                xPathImageElements.forEach(element => {
                    appendAnchorPoints(widgetProducts, element, index, setVisible, wrapElement);
                });
            } else {
                let xPathImageElements = tools.getElementByXpath(widget.element);
                for (let i = 0; i < xPathImageElements.snapshotLength; i++) {
                    let snapshotItem = xPathImageElements.snapshotItem(i);
                    appendAnchorPoints(widgetProducts, snapshotItem, index, setVisible, wrapElement);
                }
            }
        }


        /**
         * Widgets
         */
        // sort by pluginType -> first items shoud be Carousel types.
        products.widgets.sort((a, b) => (a.pluginType > b.pluginType) ? 1 : -1)
        products.widgets.map((widget, index) => {
            /**
             * Call analytics events
             */
            marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetInit, {widgetId: widget.element});
            let widgetProducts = widget.products;
            let isHTMLElement = widget.element.startsWith('data-');

            if (!widget.element) {
                return false;
            }

            switch (widget.pluginType) {
                case appGlobals.widgetTypeIinePopup:
                case appGlobals.widgetTypeIineAnchor:
                    createAnchorPoints(isHTMLElement, widget, widgetProducts, index, false);
                    break;
                case appGlobals.widgetTypeIineCarousel:
                    tools.createCarouselWidget(isHTMLElement, widget, index);
                    break;
                case appGlobals.widgetTypeIineText:
                    if (isHTMLElement) {
                        let xPathTextElements = document.querySelectorAll('[' + widget.element + ']');
                        widget.products.map(product => {
                            let productIterator = 0;
                            xPathTextElements.forEach(element => {
                                let clearText = element.innerHTML.trim().replace(/&nbsp;/g, ' ');
                                if (product.phrase && clearText.search(product.phrase.trim()) >= 0) {
                                    if (!product.allOccurrences && productIterator > 0) {
                                        return false;
                                    }
                                    if (product.allOccurrences) {
                                        element.innerHTML = clearText.replaceAll(product.phrase, '<span class="textTriggerProductPopup iineWidgetText" widget-id="' + widget.element + '" data-product="' + product.id + '"><u>' + product.phrase + '</u></span>');
                                    } else {
                                        element.innerHTML = clearText.replace(product.phrase, '<span class="textTriggerProductPopup iineWidgetText" widget-id="' + widget.element + '" data-product="' + product.id + '"><u>' + product.phrase + '</u></span>');
                                    }

                                    productIterator++;
                                }
                            })

                        })
                    } else {
                        let xPathTextElements = tools.getElementByXpath(widget.element);
                        widget.products.map(product => {
                            let productIterator = 0;
                            for (let i = 0; i < xPathTextElements.snapshotLength; i++) {
                                let snapshotItem = xPathTextElements.snapshotItem(i);
                                let clearText = snapshotItem.innerHTML.trim().replace(/&nbsp;/g, ' ');
                                if (clearText.search(product.phrase.trim()) >= 0) {
                                    if (!product.allOccurrences && productIterator > 0) {
                                        return false;
                                    }
                                    if (product.allOccurrences) {
                                        snapshotItem.innerHTML = clearText.replaceAll(product.phrase, '<span class="textTriggerProductPopup iineWidgetText" widget-id="' + widget.element + '" data-product="' + product.id + '"><u>' + product.phrase + '</u></span>');
                                    } else {
                                        snapshotItem.innerHTML = clearText.replace(product.phrase, '<span class="textTriggerProductPopup iineWidgetText" widget-id="' + widget.element + '" data-product="' + product.id + '"><u>' + product.phrase + '</u></span>');
                                    }
                                    productIterator++;
                                }
                            }
                        });
                    }


                    // add event into text
                    document.querySelectorAll('.textTriggerProductPopup').forEach(triggerElement => {
                        triggerElement.addEventListener('click', (e) => {
                            if (triggerElement.classList.contains('forceStop')) {
                                return false;
                            }
                            e.preventDefault();
                            appGlobals.activeWidgetIndex = index;
                            let productId = e.currentTarget.getAttribute('data-product');
                            // console.log(productId);
                            // let productId = 123456;
                            let productDetails = api.getProduct(productId);
                            productDetails.then(response => {
                                // customModal.show();
                                tools.setupProductLayer(response);
                            });
                        });

                        triggerElement.addEventListener('mouseenter', e => {
                            let productId = e.currentTarget.getAttribute('data-product');
                            if (!appGlobals.renderedTextWidgetProducts[productId]) {
                                let productDetails = api.getProduct(productId);
                                productDetails.then(response => {
                                    appGlobals.renderedTextWidgetProducts[productId] = response;
                                    /**
                                     * Call analytics events
                                     */
                                    //marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetHover, {widgetId: appGlobals.widgets.widgets[index].element}, [appGlobals.renderedTextWidgetProducts[productId]]);
                                });
                            } else {
                                /**
                                 * Call analytics events
                                 */
                                //marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetHover, {widgetId: appGlobals.widgets.widgets[index].element}, [appGlobals.renderedTextWidgetProducts[productId]]);
                            }
                        })
                    });
                    break;
            }

            if (index + 1 == products.widgets.length) {

                let allImages = [];
                document.querySelectorAll('.iineCarouselShadowDOM').forEach(shadowDOM => {
                    shadowDOM.shadowRoot.querySelectorAll('img').forEach(img => {
                        allImages.push(img);
                    });
                });
                Array.from(document.images).map(img => {
                    allImages.push(img);
                });

                Promise.all(Array.from(allImages).map(img => {
                    if (img.complete)
                        return Promise.resolve(img.naturalHeight !== 0);
                    return new Promise(resolve => {
                        img.addEventListener('load', () => resolve(true));
                        img.addEventListener('error', () => resolve(false));
                        return resolve(false);
                    });
                }));

                Promise.all(Array.from(allImages).map(img => {
                    if (img.complete)
                        return Promise.resolve(img.naturalHeight !== 0);
                    return new Promise(resolve => {
                        img.addEventListener('load', () => resolve(true));
                        img.addEventListener('error', () => resolve(false));
                    });
                })).then(results => {
                    let allVideos = document.querySelectorAll('video');
                    Promise.all(Array.from(allVideos).map(video => {
                        if (video.readyState >= 3) {
                            return Promise.resolve(true);
                        }
                        return new Promise(resolve => {
                            video.addEventListener('loadeddata', (e) => {
                                if (e.readyState >= 3) {
                                    resolve(true)
                                }
                            });
                        })
                    })).then(results => {
                        recalculateAnchorPointsPosition();
                        document.querySelectorAll('.iineWidgetHotspot').forEach(hotspot => {
                            hotspot.style.display = 'block';
                        });
                    });
                });
            }
        });

        tools.setActiveProductsListLayer();
        tools.updateCartLength();
        window.addEventListener('resize', recalculateAnchorPointsPosition);
        window.addEventListener('recalculateAnchorPositions', recalculateAnchorPointsPosition);

    },

    createCarouselWidget: (isHTMLElement, widget, index, isPreview = false) => {
        if (isHTMLElement) {
            let xPathCarouselElements = document.querySelectorAll('[' + widget.element + ']');
            xPathCarouselElements.forEach(element => {
                element.classList.add('iineWidgetCarousel');
                element.innerHTML = '';
                if (isPreview) {
                    setTimeout(() => {
                        tools.deleteMarketPlaceStylesTag(appGlobals.marketPlaceFrontStyles);
                    }, 1000)
                    tools.appendMarketPlaceStylesTag(appGlobals.marketPlaceCMSCarouselStyles, appConfig.scriptDomainUrl + '/dist/css/cms-carousel.css');
                    document.querySelector('html').classList.remove('iine--marketplace--app');
                    element.classList.add('iine--marketplace--app');
                }
                // let WidgetItemCarousel = WidgetCarousel(widget.products,index, widget.config);
                // element.appendChild(WidgetItemCarousel);
                // tools.initScrollBarsElement(element);

                // shadowDOM
                let div = document.createElement('div');
                let shadow = div.attachShadow({mode: 'open'});
                let carouselWrapper = document.createElement('div');
                let WidgetItemCarousel = WidgetCarousel(widget.products, index, widget.config, carouselWrapper);
                div.setAttribute('widget-id', widget.element);

                element.appendChild(div);

                carouselWrapper.className = 'iine--marketplace--app';
                carouselWrapper.appendChild(WidgetItemCarousel);
                shadow.appendChild(carouselWrapper);

                let wrapper = carouselWrapper.querySelector('.carousel--wrapper');
                tools.appendShadowDomStyles('front-carousel', appConfig.scriptDomainUrl + '/dist/css/front.css', shadow);
                Scrollbar.init(wrapper, {
                    alwaysShowTracks: true
                });

            })
        } else {
            let xPathCarouselElements = tools.getElementByXpath(widget.element);
            for (let i = 0; i < xPathCarouselElements.snapshotLength; i++) {
                let snapshotItem = xPathCarouselElements.snapshotItem(i);
                snapshotItem.innerHTML = '';
                // let WidgetItemCarousel = WidgetCarousel(widget.products, index,widget.config);
                // snapshotItem.appendChild(WidgetItemCarousel);
                // tools.initScrollBarsElement(snapshotItem);

                // shadowDOM
                let div = document.createElement('div');
                div.className = 'iineCarouselShadowDOM';
                let shadow = div.attachShadow({mode: 'open'});
                let carouselWrapper = document.createElement('div');
                let WidgetItemCarousel = WidgetCarousel(widget.products, index, widget.config, carouselWrapper);
                div.setAttribute('widget-id', widget.element);

                carouselWrapper.className = 'iine--marketplace--app';
                carouselWrapper.appendChild(WidgetItemCarousel);
                shadow.appendChild(carouselWrapper);
                snapshotItem.appendChild(div);

                let wrapper = carouselWrapper.querySelector('.carousel--wrapper');
                tools.appendShadowDomStyles('front-carousel', appConfig.scriptDomainUrl + '/dist/css/front.css', shadow);
                Scrollbar.init(wrapper, {
                    alwaysShowTracks: true
                });
            }
        }
    },


    setupCheckoutData: async () => {
        const checkoutData = await api.getCheckoutData();
        if (!checkoutData) {
            return false;
        }
        appGlobals.shippingMethods = checkoutData.shippingMethods;
        appGlobals.paymentMethods = checkoutData.paymentMethods;
        appGlobals.shippingAddress = checkoutData.shippingDataFields;
        appGlobals.buyerAddressFields = checkoutData.buyerDataFields;
        appGlobals.invoiceAddressFields = checkoutData.invoiceDataFields;
    },

    getElementByXpath: (path, resultType = 'ANY_TYPE') => {
        return document.evaluate(path, document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
    },

    modalEventsListeners: () => {
        if (/Android [4-6]/.test(navigator.appVersion)) {
            window.addEventListener("resize", function () {
                // console.log('event call');
                if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
                    window.setTimeout(function () {
                        document.activeElement.scrollIntoViewIfNeeded();
                    }, 0);
                }
            })
        }

        // const triggerModalCloseButton = document.getElementById('triggerCloseModal');
        // document.querySelectorAll('.triggerCloseModal').forEach(button => {
        //     button.addEventListener('click', (e) => {
        //         e.preventDefault();
        //         tools.deleteMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles);
        //         customModal.hide();
        //     })
        // })

        // if (triggerModalCloseButton) {
        //     triggerModalCloseButton.addEventListener('click', function (e) {
        //         e.preventDefault();
        //         tools.deleteMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles);
        //         customModal.hide();
        //     })
        // }
    },

    appendShadowDomStyles: (id, src, element) => {
        var linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', src);
        linkElement.id = id;
        element.append(linkElement);
    },

    appendMarketPlaceStylesTag: (id, src) => {
        if (document.getElementById(id)) {
            return false;
        }

        function onStyleLoaded() {
            if (!$('.productDetailCarousel').hasClass('slick-initialized')) {
                return;
            }
            $('.productDetailCarousel').slick('refresh');
        }

        var linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', src);
        linkElement.addEventListener('load', onStyleLoaded, false);
        linkElement.id = id;
        document.body.append(linkElement);
    },


    deleteMarketPlaceStylesTag: (id) => {
        let style = document.getElementById(id);
        if (style) {
            style.remove();
        }
    },

    setPopupLayer(layerId, layer) {
        window.document.getElementById(layerId).replaceWith(layer);
        tools.createCookieLayer(layer);
        tools.setActivePopupLayer(layerId);
    },
    createCookieLayer(layer) {
        let cookieAllowed = tools.getCookieConsent();

        if (!cookieAllowed) {
            const cookieLayer = CookieConsent();
            layer.appendChild(cookieLayer);
        }
    },

    setActivePopupLayer(layerId) {
        const appLayers = document.querySelectorAll('.single--layer-app ');
        appLayers.forEach((layerItem) => {
            layerItem.style.display = 'none';
        });

        let layerElement = document.getElementById(layerId);
        let layerHeaderCartComponent = layerElement.querySelector('.app--header--cart');
        let child = layerElement.querySelector('.header--cart--component');

        if (!child && layerHeaderCartComponent) {
            tools.updateHeaderCartComponentHtml(layerHeaderCartComponent);
        }

        layerElement.style.display = 'flex';

        setTimeout(() => {
            tools.initScrollbars(layerElement);
        }, 300)
    },

    setActiveProductsListLayer() {
        const ProductsListContent = ProductListContent();
        tools.setPopupLayer('productListLayer', ProductsListContent);
        tools.setPopupProductsListTitle();

        /**
         * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetOpen, {}, marketPlaceAnalytics.currentProductsList);
    },

    setPopupProductsListTitle(productTitle = null) {
        let popupProductListTitle = document.getElementById('productListHeader');
        let popupProductDetailTitle = document.getElementById('productDetailHeader');
        let title;
        if (appGlobals.blockApiCalls) {
            title = appGlobals.previewCarouselProducts.title;
        } else {
            title = (appGlobals.activeWidgetIndex >= 0 && appGlobals.widgets.widgets[appGlobals.activeWidgetIndex].title) ? appGlobals.widgets.widgets[appGlobals.activeWidgetIndex].title : '';
        }
        if (popupProductListTitle) {
            popupProductListTitle.innerHTML = title;
        }
        if (popupProductDetailTitle) {
            popupProductDetailTitle.innerHTML = title;
        }

        if (productTitle) {
            popupProductDetailTitle.innerHTML = productTitle;
        }
    },

    initScrollbars(layerElement) {
        if (window.matchMedia("(min-width: 768px)").matches) {
            tools.initScrollBarsElement(layerElement);
        }
    },

    initScrollBarsElement(layerElement) {
        var scrollbars = Scrollbar.initAll({
            alwaysShowTracks: true
        });

        scrollbars.map(scrollbar => {
            if (scrollbar.containerEl.classList.contains('productListContainerScroll')) {
                setTimeout(() => {
                    let scrollbarContainer = $(scrollbar.containerEl);
                    let item = scrollbarContainer.find('.singleProductItem')[0];
                    if (!item) {
                        return false;
                    }
                    let totalItems = scrollbarContainer.find('.singleProductItem').length;
                    let itemHeight = Math.round(item.getBoundingClientRect().height);
                    let itemWidth = Math.round(item.getBoundingClientRect().width);

                    if (!itemHeight || !itemWidth) {
                        return false;
                    }

                    let parentWidth = scrollbarContainer.find('.product--list').width();
                    let elementsInRow = Math.floor(parentWidth / itemWidth);
                    let totalRows = Math.ceil(totalItems / elementsInRow);


                    scrollbar.addListener(function (options) {
                        for (let i = 0; i < totalRows; i++) {
                            if (options.offset.y > itemHeight * i && options.offset.y < itemHeight * (i + 1) && !marketPlaceAnalytics.triggeredNestedScrollLevel[i] && !marketPlaceAnalytics.triggeredNestedScrollLevel[i + 1]) {
                                marketPlaceAnalytics.triggeredNestedScrollLevel[i] = true;
                                let elements = {};
                                let products = [];
                                if (i == 0) {
                                    elements.from = 1;
                                    elements.to = elementsInRow;
                                }
                                if (i > 0) {
                                    elements.from = (i * elementsInRow) + 1;
                                    elements.to = (i + 1) * elementsInRow;
                                }

                                for (let j = elements.from - 1; j < elements.to; j++) {
                                    products.push(marketPlaceAnalytics.currentProductsList[j])
                                }

                                /**
                                 * Call analytics events / New add
                                 */
                                if (appGlobals.activeWidgetIndex) {
                                    marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetProductModalImpression, {widgetId: appGlobals.widgets.widgets[appGlobals.activeWidgetIndex].element}, products);
                                }
                            }
                        }
                    });
                }, 200)
            }
        })
    },

    refreshScrollbarElement(scrollbarIdElement) {
        const element = document.getElementById(scrollbarIdElement);

        let scrollbar = Scrollbar.get(document.getElementById(scrollbarIdElement));
        if (!scrollbar) {
            tools.initScrollbars(element);
        }
        if (scrollbar) {
            scrollbar.update();
        }
    },

    initFlexibleContent() {
        const popup = document.querySelector('.marketPlacePopup');
        const container = document.querySelector('.marketPlaceApp');
        const popupBaseWidth = 1110;
        const popupBaseHeight = 735;
        const popupBaseFont = 16;
        const desktopMargin = 100;

        const handleResize = () => {
            // handle max width of popup
            if (window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(max-width: 1210px)").matches) {
                const fontAdjusted = ((window.innerWidth - desktopMargin) * popupBaseFont) / popupBaseWidth;
                container.style.fontSize = `${fontAdjusted}px`;
            } else if (window.matchMedia("(max-width: 767px)").matches) {
                container.style.fontSize = '12px';
            } else if (window.matchMedia("(min-width: 1210px)").matches) {
                container.style.fontSize = `${popupBaseFont}px`;
            }

            // handle max height of popup
            if (window.matchMedia("(min-width: 768px)").matches && window.innerHeight - desktopMargin < popup.clientHeight) {
                const fontAdjusted = ((window.innerHeight - desktopMargin) * popupBaseFont) / popupBaseHeight;
                container.style.fontSize = `${fontAdjusted}px`;
            }
        }

        window.addEventListener('resize', handleResize);
        setTimeout(() => {
            handleResize();
        }, 500);
    },

    initProductDetailCarousel() {
        $(document).ready(function () {
            setTimeout(function () {
                // tools.createProductDetailCarousels();
            }, 500);
        })
    },

    createHistoryCarousel() {
        $(document).ready(function () {

            $('.summaryHistoryMobile').not('.slick-initialized').slick({
                fade: true,
                accessibility: true,
                adaptiveHeight: true,
                arrows: false,
                infinite: false,
                centerMode: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            dots: true
                        }
                    }
                ]
            });

            $('.summaryHistoryMobile').on('afterChange', function (event, slick, currentSlide, nextSlide) {

                var nextArrow = $(this).parent().find('.summaryHistoryNext');
                var prevArrow = $(this).parent().find('.summaryHistoryPrev');

                prevArrow.css('opacity', '1');
                nextArrow.css('opacity', '1');

                if (slick.currentSlide >= slick.slideCount - 1) {
                    nextArrow.css('opacity', '0.2');
                }

                if (slick.currentSlide <= 0) {
                    prevArrow.css('opacity', '0.2');
                }
            });


            $(".summaryHistoryMobile").slick('slickGoTo', $('.summaryHistoryMobile div').length);
            $('.summaryHistoryNext').click(function () {
                $(this).parent().find('.slick-slider').slick('slickNext');
            });
            $('.summaryHistoryPrev').click(function () {
                $(this).parent().find('.slick-slider').slick('slickPrev');
            });
        });

    },
    createProductDetailCarousels() {
        $(document).ready(function () {
            $('.productDetailCarouselThumbs').not('.slick-initialized').slick({
                vertical: true,
                slidesToShow: 7,
                asNavFor: '.productDetailCarousel',
                arrows: false,
                focusOnSelect: true
            });
            $('.productDetailCarousel').not('.slick-initialized').slick({
                fade: true,
                asNavFor: '.productDetailCarouselThumbs',
                prevArrow: `<button type='button' class='carousel--arrow -prev'><img src='${appConfig.scriptDomainUrl + arrowCarouselSrc}' class='img-fluid' /></button>`,
                nextArrow: `<button type='button' class='carousel--arrow -next'><img src='${appConfig.scriptDomainUrl + arrowCarouselSrc}' class='img-fluid' /></button>`,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            dots: true
                        }
                    }
                ]
            });

            let beforeChangeImage = '';
            $(".productDetailCarousel").on("beforeChange", function (event, slick, currentSlide) {
                beforeChangeImage = $(slick.$slides.get(currentSlide)).find('img').attr('src');
            });
            $('.productDetailCarousel').on('afterChange', function (event, slick, currentSlide) {
                let afterChangeImage = $(slick.$slides.get(currentSlide)).find('img').attr('src');
                /**
                 * Call analytics events
                 */
                marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ProductImageChanged, {
                    previousImage: beforeChangeImage,
                    currentImage: afterChangeImage
                }, marketPlaceAnalytics.currentProductsList);
            });

            //
            // zoom
            //
            if (window.matchMedia("(min-width: 768px)").matches) {
                // desktop
                $('.productItemCarousel > .zoom').mousemove(function (e) {
                    var offsetX, offsetY, x, y;
                    var zoomer = e.currentTarget;
                    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
                    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
                    x = offsetX / zoomer.offsetWidth * 100
                    y = offsetY / zoomer.offsetHeight * 100
                    zoomer.style.backgroundPosition = x + '% ' + y + '%';
                })
            } else {
                // mobile
                const arr = [];
                const dots = document.querySelector('.slick-dots');
                let elements = document.querySelectorAll('.productItemCarousel .zoom');
                for (var i = 0; i < elements.length; i++) {
                    arr.push(new PinchZoom(elements[i], {
                        minZoom: 1,
                        draggableUnzoomed: false,
                        onZoomStart: function (object) {
                            dots.style.display = 'none';
                        },
                        onZoomEnd: function (object, event) {
                            if (object.zoomFactor < 1.1) {
                                dots.style.display = 'flex';
                            } else {
                                dots.style.display = 'none';
                            }
                        },
                        onDoubleTap: function (object, event) {
                            if (object.zoomFactor > 1) {
                                dots.style.display = 'flex';
                            } else {
                                dots.style.display = 'none';
                            }
                        }
                    }));
                }
            }
        });

        const images = $('.productItemCarousel img').length;
        $('.productItemCarousel img').eq(images - 1).on('load', () => {
            window.dispatchEvent(new Event('resize'));
        })

    },

    initCustomSelect() {
        const selects = Array.from(document.querySelectorAll('.customSelect'));
        selects.map(select => {
            new Choices(select, {
                searchEnabled: false,
                itemSelectText: '',
                shouldSort: false,
                callbackOnInit: () => {
                    const moreThanOneOption = Array.from(select.querySelectorAll('option')).filter((option) => {
                        return option.innerHTML.startsWith('Wybierz ');
                    });
                    if (moreThanOneOption.length === 0) {
                        select.closest('.choices').classList.add('-onlyoption');
                    }
                }
            });
            select.addEventListener(
                'change',
                function (event) {
                    removeChooseOption();
                },
                false,
            );
            select.addEventListener(
                'showDropdown',
                function (event) {
                    select.closest('.choices').querySelector('.choices__list--dropdown').style.zIndex = '1';
                },
                false,
            );
            select.addEventListener(
                'hideDropdown',
                function (event) {
                    select.closest('.choices').querySelector('.choices__list--dropdown').style.zIndex = '-1';
                },
                false,
            );
        });

        const removeChooseOption = () => {
            const firstOption = Array.from(document.querySelectorAll('.choices .choices__item--choice')).filter(e => {
                return e.innerHTML.startsWith('Wybierz ');
            });
            firstOption.map(option => {
                option.style.display = 'none';
            });
        }

        removeChooseOption();
    },

    /**
     * CART ACTIONS
     */
    updateHeaderCartComponentHtml: (headerCartElement) => {
        headerCartElement.appendChild(HeaderCart);
    },

    getEventClickProduct: (event) => {
        return JSON.parse(event.target.getAttribute('data-product'));
    },

    setCart: (cart) => {
        window.localStorage.setItem(appGlobals.cartName, JSON.stringify(cart));
    },

    getCart: () => {
        return window.localStorage.getItem(appGlobals.cartName) ? JSON.parse(window.localStorage.getItem(appGlobals.cartName)) : [];
    },
    /**
     * Get localstorage Cookie consent
     * @returns {boolean} storaged cookie conset status
     */
    getCookieConsent: () => {
        const storage = localStorage.getItem('marketplace_consent');
        const cookieStatus = storage ? storage : false;
        return JSON.parse(cookieStatus);
    },
    /**
     * Set CookieConsent localStorage item
     * @param {boolean} status
     */
    setCookieConsent: function (status) {
        localStorage.setItem('marketplace_consent', JSON.stringify(status));
    },

    getCartTotalPrice: () => {
        const cart = tools.getCart();
        let totalPrice = 0;
        cart.map((product) => {
            totalPrice += (product.selectedQuantity * product.price)
        });

        return parseFloat(totalPrice);
    },

    getSelectedShippingCost: () => {
        return appGlobals.selectedDeliveryMethod ? appGlobals.selectedDeliveryMethod.cost : 0;
    },

    getDefaultProductsCurrency: () => {
        const cart = tools.getCart();
        let defaultProductsCurrency = 'PLN';
        if (cart.length) {
            defaultProductsCurrency = cart[0].currency;
        }
        return defaultProductsCurrency;
    },

    getCartProductIndexByHash: (hash) => {
        const cart = tools.getCart();
        return cart.findIndex(item => item.id == hash);
    },

    getMerchantByProductId: (id) => {
        let findedMerchant = {};
        appGlobals.preparedCart.forEach(merchant => {
            if (merchant.items.find(product => product.id == id)) {
                findedMerchant = merchant;
            }
        });
        return findedMerchant;

    },

    getCartProductIndexById: (id) => {
        const cart = tools.getCart();
        return cart.findIndex(item => item.id === id);
    },

    addProductToCart: (product) => {
        const cart = tools.getCart();
        let cartProductIndex = tools.getCartProductIndexByHash(product.id);
        if (cartProductIndex >= 0) {
            cart[cartProductIndex].selectedQuantity = cart[cartProductIndex].selectedQuantity ? cart[cartProductIndex].selectedQuantity += 1 : 1;
            /**
             * Call analytics events / Quantity change
             */
            marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.SingleProductQuantityChanged, {}, [product]);
        } else {
            product.selectedQuantity = 1;
            cart.push(product);

            /**
             * Call analytics events / New add
             */
            marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ProductAdded, {}, [product]);
        }

        tools.showLastAddedProductPopup(product);
        window.localStorage.setItem(appGlobals.cartName, JSON.stringify(cart));
        tools.updateCartLength();
    },

    removeProductFromCart: (hash) => {
        const cart = tools.getCart();
        let cartProductIndex = tools.getCartProductIndexByHash(hash);
        if (cartProductIndex >= 0) {

            /**
             * Call analytics events
             */
            marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ProductRemoved, {}, [cart[cartProductIndex]]);
            cart.splice(cartProductIndex, 1);
            window.localStorage.setItem(appGlobals.cartName, JSON.stringify(cart));
        }
    },

    setProductQuantity: (product, unAvailableQuantity) => {
        const cart = tools.getCart();
        let cartProductIndex = tools.getCartProductIndexById(product.id);
        if (cartProductIndex >= 0) {
            let newQuantity = cart[cartProductIndex].selectedQuantity - unAvailableQuantity;
            if (newQuantity > 0) {
                cart[cartProductIndex].selectedQuantity = newQuantity;
            } else {
                cart.splice(cartProductIndex, 1);
            }
            tools.setCart(cart);
            tools.updateCartLength();
        }
    },

    clearCart: () => {
        window.localStorage.setItem(appGlobals.cartName, JSON.stringify([]));
        helpers.setGuid(uuidv4());
        helpers.setUserGuid();
    },

    updateCartLength: () => {
        const cart = tools.getCart();
        const cartNumberElements = document.querySelectorAll('.cartLengthNumber');
        let productsLength = 0;
        cart.map((product) => {
            productsLength += +product.selectedQuantity ? +product.selectedQuantity : +1;
        });

        cartNumberElements.forEach(element => {
            element.innerHTML = productsLength;
        })
    },

    updateCartList: () => {
        const cartList = CartList();
        window.document.getElementById('cartList').replaceWith(cartList);
    },

    prepareGetShippingData: (changedProductId) => {
        let cartListProducts = tools.getCart();
        const index = tools.getCartProductIndexByHash(changedProductId);
        const changedProduct = cartListProducts[index];
        const merchant = tools.getMerchantByProductId(changedProductId);
        const merchantPayload = {...merchant};

        merchant.items = merchant.items.map(item =>
            item.id == changedProductId ? {...item, selectedQuantity: changedProduct.selectedQuantity} : item
        );

        merchantPayload.items = merchantPayload.items.map(item =>
            item.id == changedProductId ? {
                ...item,
                selectedQuantity: changedProduct.selectedQuantity ? changedProduct.selectedQuantity : 0,
                price: parseFloat((changedProduct.selectedQuantity).toFixed(2) ? item.price * changedProduct.selectedQuantity : 0)
            } : {...item, price: parseFloat((item.price * item.selectedQuantity).toFixed(2))}
        );

        merchantPayload.websiteId = appConfig.websiteId;
        return merchantPayload;
    },

    refreshMerchantShipping: (productId) => {
        let merchantPayload = tools.prepareGetShippingData(productId);
        let willRefresh = false;
        api.refreshShippingMethods(merchantPayload).then((res) => {

            willRefresh = !JSON.stringify(res[0].shippingMethods) == JSON.stringify(merchantPayload.shippingMethods);
            if (willRefresh) {
                const replaceShippingMerchant = appGlobals.preparedCart.find(merchant => merchant.merchantId == merchantPayload.merchantId);
                replaceShippingMerchant.shippingMethods = res[0].shippingMethods;
            }
        });
        const cartList = CartList(true, willRefresh);
    },

    updateSumUpBoxCartPrice: () => {
        let totalPrice = tools.getPreparedCartTotalPrice();
        // let deliveryPrice = appGlobals.selectedDeliveryMethod? (appGlobals.selectedDeliveryMethod.cost) : 0;
        let defaultCurrency = tools.getDefaultProductsCurrency();

        let deliveryPrice = 0;
        const carts = tools.getPreparedCart();
        carts.map((merchant) => {
            let shippingMethod = tools.getMerchantSelectedShippingMethod(merchant.merchantId);
            if (shippingMethod) {
                deliveryPrice += shippingMethod.cost;
            }
        })


        document.querySelectorAll('.autocompleteDeliveryPriceText').forEach(element => {
            let textBlock = document.createElement('span');
            textBlock.append(helpers.setFormatNumberString(deliveryPrice, defaultCurrency));
            element.innerHTML = '';
            element.append(textBlock);
        })

        document.querySelectorAll('.autocompleteCartPriceText').forEach(element => {
            let textBlock = document.createElement('span');
            textBlock.append(helpers.setFormatNumberString(totalPrice, defaultCurrency));
            element.innerHTML = '';
            element.append(textBlock);
        })

        document.querySelectorAll('.autocompleteTotalPriceText').forEach(element => {
            let textBlock = document.createElement('span');
            textBlock.append(helpers.setFormatNumberString(tools.getPreparedCartTotalPrice(true), defaultCurrency));
            element.innerHTML = '';
            element.append(textBlock);
        })
    },

    showLastAddedProductPopup: (product) => {
        const lastAddedProductBox = HeaderCartLastProductBox(product);
        document.getElementById('cartLastProductBox').replaceWith(lastAddedProductBox);
        const element = document.getElementById('cartLastProductBox');
        element.style.display = 'block';
        setTimeout(function () {
            element.style.display = 'none';
        }, 3000)
    },
    /**
     * PRODUCT ACTIONS
     */
    setupProductLayer: (product) => {
        const layer = ProductDetailContent(product);
        tools.setPopupLayer('productDetailLayer', layer);
        tools.initProductDetailCarousel();
        tools.initCustomSelect();
        tools.setPopupProductsListTitle();
        customModal.show();
        tools.initFlexibleContent();
        appGlobals.selectedProductPrice = product.price;
        appGlobals.loadedProductImages = false;

        /**
         * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ProductDetailsViewed, {}, [product]);
    },

    getSelectedProductDetail: (detail) => {
        const select = document.getElementsByName(detail)[0];
        if (!select) {
            return false;
        }
        return select.options[select.selectedIndex].value;
    },

    findSelectedVariantProduct: (product) => {
        let productSize = tools.getSelectedProductDetail('product_size');
        let productColor = tools.getSelectedProductDetail('product_color');
        let selectedVariant = null;
        if (!product.variants) {
            return selectedVariant;
        }
        product.variants.map(variant => {
            if (!selectedVariant && productSize && productColor && variant.color === productColor && variant.size === productSize) {
                selectedVariant = variant;
            } else if (!selectedVariant && productSize && !productColor && productSize === variant.size) {
                selectedVariant = variant;
            } else if (!selectedVariant && !productSize && productColor && productColor === variant.color) {
                selectedVariant = variant;
            }
        });
        return selectedVariant;
    },

    getPreparedCart: () => {
        return appGlobals.preparedCart || [];
    },

    setPreparedCart: (cart) => {
        appGlobals.preparedCart = cart;
    },

    /**
     * Pobiera zaznaczone shipping method z wygenerowanego koszyka
     * @returns {*}
     * @param merchantId
     */
    getMerchantSelectedShippingMethod: (merchantId) => {
        // gdy mamy z AMP ustawione selectedShippingMethods i jest automatyczne przekierowanie do koszyka
        if (appGlobals.selectedShippingMethods && appGlobals.selectedShippingMethods[merchantId]) {
            return appGlobals.selectedShippingMethods[merchantId];
        }

        const preparedCart = tools.getPreparedCart();
        const merchant = preparedCart.find((item) => item.merchantId == merchantId);
        let selectedShippingMethod = merchant.shippingMethods.find((item) => item.id == merchant.shippingMethod);
        // if(!selectedShippingMethod){
        //     return merchant.shippingMethods[0];
        // }
        return selectedShippingMethod;
    },

    isShippingMethodSelected: () => {
        const preparedCart = tools.getPreparedCart();

        let selected = true;
        preparedCart.map((merchant, index) => {
            if (!merchant.shippingMethod) {
                document.querySelectorAll('.delivery--types--container')[index].classList.add('-error');
                selected = false;
            }
        })
        return selected;
    },

    getMerchantItemsPrice: (merchantId, withDelivery) => {
        const preparedCart = tools.getPreparedCart();
        const merchant = preparedCart.find((item) => item.merchantId == merchantId);

        let price = 0;
        merchant.items.map(item => {
            price += item.selectedQuantity * item.price;
        })

        if (withDelivery) {
            const shippingMethod = tools.getMerchantSelectedShippingMethod(merchantId);
            if (shippingMethod) {
                price += shippingMethod.cost;
            }

        }

        return price;
    },

    getPreparedCartTotalPrice: (withDelivery) => {
        const cart = tools.getPreparedCart();
        let totalPrice = 0;
        if (!cart.length) {
            return totalPrice;
        }

        cart.map((merchant) => {
            totalPrice += tools.getMerchantItemsPrice(merchant.merchantId, withDelivery);
        })

        return totalPrice
    },


    getPreparedCartDeliveryPrice: () => {
        const cart = tools.getPreparedCart();
        let totalPrice = 0;
        cart.map((merchant) => {
            const shippingMethod = tools.getMerchantSelectedShippingMethod(merchant.merchantId);
            totalPrice += shippingMethod.cost;
        })

        return totalPrice
    },

    renderSummaryMerchantListCheckout: () => {
        setTimeout(() => {
            let el = document.getElementById('merchantsListSummary');
            if (el) {
                el.replaceWith(MerchantListSummary())
            }
        }, 100)
    },

    isProductAvailableToAddToCart: (product, productColor, productSize) => {
        // todo dorobic opcje sprawdzania czy juz  w koszytku mamy taki produkt i ile sztuk
        let isAvailableToAddToCart = false;
        product.variants.map(variant => {
            if (variant.color === productColor && variant.size === productSize && variant.availableQuantity) {
                isAvailableToAddToCart = true;
            }
        });

        return isAvailableToAddToCart;
    },

    getDisabledProductVariantOptions: (product) => {
        let cart = tools.getCart();
        let productSize = document.querySelector('select[name="product_size"]');
        let productColor = document.querySelector('select[name="product_color"]');

        let disabledOptions = [];
        cart.map(cartProduct => {
            if (cartProduct.parentId === product.id) {
                let quantity = cartProduct.selectedQuantity;
                let variant;
                if (productSize && productColor) {
                    variant = product.variants.find(x => {
                        return x.color === cartProduct.color && x.size === cartProduct.size
                    })
                } else if (productSize && !productColor) {
                    variant = product.variants.find(x => {
                        return x.size === cartProduct.size
                    })

                } else if (productColor && !productSize) {
                    variant = product.variants.find(x => {
                        return x.color === cartProduct.color
                    })
                }

                if (variant && variant.availableQuantity <= quantity) {
                    disabledOptions.push(variant);
                }
            }
        });

        if (product.variants) {
            let availableQuantityVariants = [];
            product.variants.map(variant => {
                if (variant.availableQuantity) {
                    availableQuantityVariants.push(variant)
                }
            })

            product.variants.map(variant => {
                if (!variant.availableQuantity && availableQuantityVariants.indexOf(variant) < 0) {
                    disabledOptions.push(variant);
                }
            });
        }

        return disabledOptions;
    },

    setDisabledProductVariantOptions: (product) => {
        let disabledOptions = tools.getDisabledProductVariantOptions(product);
        let productSize = document.querySelector('select[name="product_size"]');
        let productColor = document.querySelector('select[name="product_color"]');


        if (productSize && disabledOptions) {
            const options = Array.from(productSize.parentNode.nextSibling.querySelectorAll('.choices__item--choice'));
            options.map(option => {
                option.classList.remove('choices__item--disabled');

                if (appGlobals.currentSelectedVariants && appGlobals.currentSelectedVariants.size) {
                    if (!appGlobals.currentSelectedVariants.size.find(x => x === option.getAttribute('data-value'))) {
                        option.classList.add('choices__item--disabled');
                    }
                }

                let itemVariant = disabledOptions.find(item => item.size === option.getAttribute('data-value'));
                if (itemVariant) {
                    if (productColor && itemVariant.color == productColor.value) {
                        option.classList.add('choices__item--disabled');
                        if (option.getAttribute('data-value') === productSize.querySelector('option').value) {
                            productSize.querySelector('option').value = '';
                            productSize.parentElement.querySelector('.choices__item').innerHTML = 'Wybierz rozmiar';
                        }
                    }
                }
            });
        }

        if (productColor && disabledOptions) {
            const options = Array.from(productColor.parentNode.nextSibling.querySelectorAll('.choices__item--choice'));
            options.map(option => {
                option.classList.remove('choices__item--disabled');
                // if (disabledOptions.find(item => item.color === option.getAttribute('data-value'))) {
                //     option.classList.add('choices__item--disabled');
                // }
            });
        }

        setTimeout(() => {
            if (productSize && productSize.value && productColor && productColor.value && productColor.parentElement.parentElement.querySelectorAll('.choices__item').length > 1) {
                const options = Array.from(productColor.parentNode.nextSibling.querySelectorAll('.choices__item--choice'));
                options.map(option => {
                    option.classList.remove('choices__item--disabled');
                })
            }

            if (productSize && productSize.value) {
                const options = Array.from(productSize.parentNode.nextSibling.querySelectorAll('.choices__item--disabled'));
                options.map(option => {
                    if (option.getAttribute('data-value') === productSize.value) {
                        productSize.querySelector('option').value = '';
                        productSize.parentElement.querySelector('.choices__item').innerHTML = 'Wybierz rozmiar';
                    }
                })
            }

            if (productSize && appGlobals.currentSelectedVariants && appGlobals.currentSelectedVariants.size) {
                appGlobals.currentSelectedVariants.size.map(size => {
                    let opt = document.querySelector('.choices__item[data-value="' + size + '"]');
                    if (opt) {
                        opt.classList.remove('choices__item--disabled');
                    }
                })
            }

            // if(productSize){
            //     const options = Array.from(productSize.parentNode.nextSibling.querySelectorAll('.choices__item--choice'));
            // options.map(option => {
            //
            // })
            // }
            //

        }, 10)

    },


    refreshProductVariantOptions: () => {
        document.querySelectorAll('.eventSelectInput').forEach(selector => {
            selector.dispatchEvent(new Event("change"))
        })
    },

    generateProductHash: (id, size, color) => {
        return (id + '-' + size + '-' + color).toUpperCase();
    },

    scrollToTop: () => {
        $('#marketPlaceApplication').animate({scrollTop: 0}, 500);

        let el = document.getElementById('checkoutLayer');
        el.scrollIntoView(true)
    },

    scrollToError: () => {
        if (window.matchMedia("(min-width: 768px)").matches) {
            const scrollbar = Scrollbar.get(document.getElementById('checkoutContainer'));

            scrollbar.scrollIntoView($('div.-error').get(0), {
                offsetLeft: 34,
                offsetBottom: 12,
                alignToTop: false,
                onlyScrollIfNeeded: true,
            });
        }
    },

    clamp: () => {
        const clamp = require('text-overflow-clamp');
        const productDescription = document.querySelectorAll('.carousel--wrapper .product--description');
        Array.from(productDescription).map(description => {
            // clamp(description, 2)
        });

        document.querySelectorAll('.iineCarouselShadowDOM').forEach(iineCarouselWrapper => {
            let elements = iineCarouselWrapper.shadowRoot.querySelectorAll('.carousel--wrapper .product--description');
            Array.from(elements).map(description => {
                // clamp(description, 2)
            });
        })
    },

    toggleAutocompletePersonalInvoice: (isPersonalInvoice) => {
        let autocompleteCompanyInvoice = document.querySelectorAll('.autocompleteCompanyInvoice');
        let autocompletePersonalInvoice = document.querySelectorAll('.autocompletePersonalInvoice');

        if (!autocompleteCompanyInvoice || !autocompletePersonalInvoice) {
            return false;
        }

        if (isPersonalInvoice) {
            autocompleteCompanyInvoice.forEach(el => {
                el.style.display = 'none';
            })
            autocompletePersonalInvoice.forEach(el => {
                el.style.display = 'flex';
            })
        } else {
            autocompleteCompanyInvoice.forEach(el => {
                el.style.display = 'flex';
            })
            autocompletePersonalInvoice.forEach(el => {
                el.style.display = 'none';
            })
        }
    },

    hasGeowidgetShippingMethod: () => {
        const cart = tools.getPreparedCart();
        let hasGeowidget = false;
        cart.map(merchant => {
            let shippingMethod = tools.getMerchantSelectedShippingMethod(merchant.merchantId);
            if (shippingMethod && shippingMethod.showGeowidget === true) {
                hasGeowidget = true;
            }
        })

        return hasGeowidget;
    },

    getSelectedShippingDataInpost: () => {
        let data = {};
        if (!appGlobals.selectedInpostData) {
            return {};
        }

        data.targetPoint = appGlobals.selectedInpostData.name;
        data.streetName = appGlobals.selectedInpostData.address_details.street;
        data.houseNumber = (appGlobals.selectedInpostData.address_details.building_number).substring(0, 6);
        data.flatNumber = appGlobals.selectedInpostData.address_details.flat_number;
        data.city = appGlobals.selectedInpostData.address_details.city;
        data.postalCode = appGlobals.selectedInpostData.address_details.post_code;
        data.country = "PL";
        return data;
    },

    handleAmpCart: () => {
        const handleAmpCart = tools.getUrlParam(appGlobals.handleAmpCartQueryString);
        if (handleAmpCart) {
            appGlobals.handleAmp = true;
            let cart = tools.getCart();
            cart = [];
            if (!cart) {
                return false;
            }
            Promise.all(cart.map((item) => {
                return new Promise((resolve, reject) => {
                    if (!item.variants) {
                        api.getProduct(item.productId).then((res) => {
                            item.variants = res.variants;
                            tools.setCart(cart);
                            resolve(true);
                        })
                    } else {
                        resolve(true);
                    }
                })
            })).then(() => {
                setTimeout(() => {
                    let triggerCartWidgetPopup = document.querySelector('.triggerCartWidgetPopup');
                    if (triggerCartWidgetPopup) {
                        const selectedMerchantShippingMethod = window.localStorage.getItem(appGlobals.cartMethods);
                        if (selectedMerchantShippingMethod) {
                            appGlobals.selectedShippingMethods = JSON.parse(selectedMerchantShippingMethod);
                            // usuwamy LS z wybranymi metodami dostawy, jesli uzytkownik to juz widział?
                            window.localStorage.removeItem(appGlobals.cartMethods);
                        }
                        // reszta logiki w MerchantItem. Trigger clicka dalej
                        triggerCartWidgetPopup.click();
                    }
                }, 200)
            })
        }
    },

    getUrlParam: name => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

};


/**
 *  MODAL
 */
const customModal = {
    show: function (modal) {
        // tools.appendMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles, appConfig.scriptDomainUrl+'/dist/css/main.css');

        const app = document.getElementById(appGlobals.appId);
        if (!modal) {
            modal = document.getElementById('marketPlaceModal');
        }
        app.style.display = 'block';
        app.classList.add('active');
        modal.classList.add('active');
        document.body.style.overflow = 'hidden';

        setTimeout(() => {
            document.querySelector('html').classList.add('iine--application--show');
            document.querySelectorAll('.iineWidgetHotspot').forEach(element => {
                element.style.display = 'none'
            });
        }, 1)
    },

    hide: function (modal) {
        document.querySelector('html').classList.remove('iine--application--show');
        // tools.deleteMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles);
        window.dispatchEvent(new Event('recalculateAnchorPositions'));
        document.querySelectorAll('.iineWidgetHotspot').forEach(element => {
            element.style.display = 'none'
        });
        const app = document.getElementById(appGlobals.appId);
        if (!modal) {
            modal = document.getElementById('marketPlaceModal');
        }

        setTimeout(() => {
            app.classList.remove('active');
            app.style.display = 'none';
            modal.classList.remove('active');
            document.body.style.overflow = '';
        }, 100);

        setTimeout(() => {
            document.querySelectorAll('.iineWidgetHotspot').forEach(element => {
                element.style.display = 'block'
            });
        }, 300)
        // window.dispatchEvent(new Event('recalculateAnchorPositions'));
        /**
         * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetClosed, {}, []);
    }
};

/**
 * HELPERS
 */
const helpers = {
    parseString: string => {
        const htmlElem = document.createElement('span');
        htmlElem.innerHTML = string;
        return htmlElem;
    },

    setFormatNumberString(price, currency = null) {
        let lang = window.navigator.languages ? window.navigator.languages[0] : null;
        lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
        let currencyText = 'zł';

        if (!currency || currency == 'zł') {
            currency = 'PLN';
            lang = "pl";
        }
        if (currency == 'PLN' || currency == 'zł') {
            lang = "fi-FI";
            currencyText = 'zł';
        } else {
            currencyText = currency;
        }

        let formatter = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        });

        let parts = formatter.formatToParts(price);
        let currencyString = parts.find(el => el.type === "currency");
        let priceString = formatter.format(price);
        if (priceString) {
            priceString = priceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            priceString = priceString.replace(currencyString.value, '<span class="currency">' + currencyText + '</span>');
        }
        return helpers.parseString(priceString);
    },

    isValidEmail: (email) => {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    },

    isValidPhone: phone => {
        return (/(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-68]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}/).test(phone);
    },

    getCoords(elem) {
        // crossbrowser version
        var box = elem.getBoundingClientRect();

        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return {top: Math.round(top), left: Math.round(left)};
    },

    testRegex: (regex, value) => {

        regex = regex.replace('\\\\', '\\');
        let re = RegexParser(regex);
        let matched = value.match(re);
        return matched;


        // console.log(re);
        // return re.test(value);
    },

    validateForm: (form) => {
        let data = {};
        let details = {};
        data.isValid = true;
        let validationFields = form.querySelectorAll('.formValidationItem');

        //clear all errors
        form.querySelectorAll('.error--block').forEach(element => {
            element.remove();
        });
        form.querySelectorAll('.-error').forEach(element => {
            element.classList.remove('-error');
        })

        // invoice form data
        if (form.querySelector('input[name="cart_is_invoice"]').checked) {
            appGlobals.invoiceAddressFields.forEach(item => {
                let formItem = form.querySelector('input[data-api-field="invoiceAddress"][data-api-name="' + item.name + '"]');
                if (formItem) {
                    formItem.setAttribute('data-required', item.required);
                }
            });
        } else {
            form.querySelectorAll('input[data-api-field="invoiceAddress"]').forEach(element => {
                element.setAttribute('data-required', false);
            })
        }

        validationFields.forEach(input => {
            let isRequired = input.getAttribute('data-required');
            let type = input.getAttribute('type');
            let value = input.value;
            let name = input.getAttribute('name');
            isRequired = isRequired == true || isRequired == 'true' || isRequired === 'required';

            switch (type) {
                case 'checkbox':
                    let checkboxChecked = input.checked ? 1 : 0;
                    details[name] = checkboxChecked;
                    // isRequired && !checkboxChecked ? input.closest('.checkbox--wrapper').classList.add('-error') : input.closest('.checkbox--wrapper').classList.remove('-error');
                    break;
                case 'textarea':
                    details[name] = value;
                    break;
                case 'email':
                    details[name] = value;
                    isRequired && !value ? input.closest('.input--text--component').classList.add('-error') : input.closest('.input--text--component').classList.remove('-error');
                    break;
                case 'select':
                    let selectedOption = input.options[input.selectedIndex].value;
                    details[name] = selectedOption;
                    break;
                case 'radio':
                    let selectedRadioOption = form[name].value;
                    details[name] = selectedRadioOption;
                    isRequired && !selectedRadioOption ? input.closest('.input--radio--component').classList.add('-error') : input.closest('.input--radio--component').classList.remove('-error');
                    break;
                default:
                    details[name] = value;
                    isRequired && !value ? input.closest('.input--text--component').classList.add('-error') : input.closest('.input--text--component').classList.remove('-error');
            }

            helpers.validateInputField(input);
        });

        data.details = details;
        if (form.querySelectorAll('.-error')[0]) {
            data.isValid = false;
            let firstError = form.querySelectorAll('.-error')[0];
            firstError.scrollIntoView({behavior: 'smooth'});
        }

        return data;
    },

    validateInputField: (input, forceRequired) => {
        let isRequired = input.getAttribute('data-required');
        let type = input.getAttribute('type');
        let minLength = input.getAttribute('data-minLength');
        let maxLength = input.getAttribute('data-maxLength');
        let regex = input.getAttribute('data-regex');
        let regexErrorMessage = input.getAttribute('data-regex-message');

        isRequired = isRequired == true || isRequired == 'true' || isRequired === 'required';

        if (forceRequired) {
            isRequired = true;
        }


        function removeErrorBlock(input) {
            if (type != 'checkbox' && input.closest('.input--text--component')) {
                input.closest('.input--text--component').classList.remove('-error');
                input.closest('.input--text--component').querySelectorAll('.error--block').forEach(errorBlock => {
                    errorBlock.remove();
                });
            }
            if (type === 'checkbox' && input.closest('.checkbox--wrapper')) {
                input.closest('.checkbox--wrapper').classList.remove('-error');
                input.closest('.checkbox--wrapper').querySelectorAll('.error--block').forEach(errorBlock => {
                    errorBlock.remove();
                });
            }
        }

        removeErrorBlock(input);


        if (input.value.length > 0 && minLength > 0 && input.value.length < minLength) {
            input.closest('.input--text--component').classList.add('-error');
        }

        if (input.value.length > 0 && maxLength > 0 && input.value.length > maxLength) {
            input.closest('.input--text--component').classList.add('-error');
        }

        if (type === 'checkbox' && isRequired && !input.checked) {
            input.closest('.checkbox--wrapper').classList.add('-error');
        }

        if (type != 'checkbox' && isRequired && !input.value) {
            input.closest('.input--text--component').classList.add('-error');
        }


        if (input.value.length > 0 && regex) {
            let regexCheck = helpers.testRegex(regex, input.value);
            if (!regexCheck) {
                input.closest('.input--text--component').classList.add('-error');
            }
        }

        if (type === 'checkbox' && isRequired && input.closest('.checkbox--wrapper').classList.contains('-error')) {
            let errorBlock = document.createElement('div');
            errorBlock.classList.add('error--block');
            errorBlock.innerHTML = 'Pole musi zostać zaznaczone';
            input.closest('.label').append(errorBlock);
        }

        if (type !== 'checkbox' && input.closest('.input--text--component') && input.closest('.input--text--component').classList.contains('-error')) {
            let errorBlock = document.createElement('div');
            errorBlock.classList.add('error--block');
            if (!input.value.length) {
                errorBlock.innerHTML = 'Pole jest wymagane';
            } else if (input.value.length > 0 && input.value.length < minLength) {
                let minLengthText = minLength > 4 ? 'znaków' : 'znaki';
                errorBlock.innerHTML = 'Pole powinno zawierać minimalnie ' + minLength + ' ' + minLengthText;
            } else if (input.value.length > 0 && input.value.length > maxLength) {
                let maxLengthText = maxLength > 4 ? 'znaków' : 'znaki';
                errorBlock.innerHTML = 'Pole powinno zawierać maksymalnie ' + maxLength + ' ' + maxLengthText;
            } else if (regex) {
                errorBlock.innerHTML = regexErrorMessage ? regexErrorMessage : 'Format wprowadzonych danych jest nieprawidłowy';
            }

            input.closest('.input--text--component').append(errorBlock);
        }
    },

    replaceInText: (element, pattern, replacement) => {
        for (let node of element.childNodes) {
            switch (node.nodeType) {
                case Node.ELEMENT_NODE:
                    helpers.replaceInText(node, pattern, replacement);
                    break;
                case Node.TEXT_NODE:
                    node.textContent = node.textContent.replace(pattern, replacement);
                    break;
                case Node.DOCUMENT_NODE:
                    helpers.replaceInText(node, pattern, replacement);
            }
        }
    },

    setupApiValidateFields: (form) => {

        function setupFieldAttributes(field, type) {
            form.querySelectorAll('[data-api-name="' + field.name + '"]').forEach(input => {
                if (input.getAttribute('data-api-field') != type) {
                    return false;
                }
                input.setAttribute('data-minLength', field.minLength);
                input.setAttribute('data-maxLength', field.maxLength);
                input.setAttribute('data-required', field.required);
                if (field.regex) {
                    input.setAttribute('data-regex', field.regex)
                }
                if (field.regexErrorMessage) {
                    input.setAttribute('data-regex-message', field.regexErrorMessage)
                }
                if (field.tip) {
                    if (!input.closest('.input--text--component').querySelector('.input--info--component')) {
                        let tooltip = CheckoutInputInfo(field.tip);
                        input.after(tooltip)
                    }
                }

                if (field.required) {
                    if (input.closest('.input--text--component')) {
                        let label = input.closest('.input--text--component').querySelector('.label');
                        if (label) {
                            if (label.querySelector('.required--component')) {
                                return false;
                            }
                            let requiredSpan = document.createElement('span');
                            requiredSpan.classList.add('required--component');
                            requiredSpan.innerHTML = '*';
                            label.append(requiredSpan);
                        }
                    }

                }

                if (field.items) {
                    let items = field.items;
                    let itemValue = items[Object.keys(items)[0]];
                    if (itemValue) {
                        input.value = itemValue;
                        input.setAttribute('disabled', 'disabled');
                    }
                }
            })
        }

        if (appGlobals.shippingAddress) {
            appGlobals.shippingAddress.map(field => {
                setupFieldAttributes(field, 'shippingAddress');
            })
        }
        if (appGlobals.buyerAddressFields) {
            appGlobals.buyerAddressFields.map(field => {
                setupFieldAttributes(field, 'buyerAddress');
            })
        }
        if (appGlobals.invoiceAddressFields) {
            appGlobals.invoiceAddressFields.map(field => {
                setupFieldAttributes(field, 'invoiceAddress');
            })
        }
    },

    generateGuid: () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    setUserGuid: () => {
        const userFingerPrint = new IineFingerprint();
        userFingerPrint._createFingerprint();
    },

    getUserGuid: () => {
        let userGuid = marketPlaceAnalytics.getUserDataFingerPrint();
        if (!userGuid) {
            const userFingerPrint = new IineFingerprint();
            userFingerPrint._createFingerprint();
        }
        return userGuid.fingerprint;
    },

    getProductsByWidgetId: (widgetId) => {
        let widget = appGlobals.widgets.widgets.find(el => el.element == widgetId);
        return widget ? widget.products : [];
    },

    setGuid: (guid) => {
        window.localStorage.setItem('guid', guid);
    },

    getGuid: () => {
        let guid = window.localStorage.getItem('guid');
        if (!guid) {
            const newGuid = uuidv4();
            helpers.setGuid(newGuid)
            return newGuid;
        }
        return guid;
    }
};

const preloader = {
    show: (title = 'Proszę czekać') => {
        let preLoader = Preloader(title);
        document.body.append(preLoader);
    },
    hide: () => {
        let preLoader = document.getElementById('iinePreLoader');
        if (preLoader) {
            preLoader.parentNode.removeChild(preLoader);
        }
    }
};


const toast = {
    show: (title = 'Zapisano') => {
        let toastComponent = Toast(title);
        document.body.append(toastComponent);
        setTimeout(() => {
            toast.hide();
        }, 4000)
    },
    hide: () => {
        let toastComponent = document.getElementById('toastComponent');
        if (toastComponent) {
            toastComponent.parentNode.removeChild(toastComponent);
        }
    }
};


export {
    tools,
    customModal,
    helpers,
    preloader,
    toast

};

document.addEventListener('DOMContentLoaded', function () {
    tools.init();
})

window.iineMarketplaceSetup = tools.init;
// document.addEventListener('DOMContentLoaded', function () {
//     tools.init();
// });
