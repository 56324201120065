import { createElement } from "../../createElement";
import { tools } from "../../tools";
import appConfig from "../../config";
import basketIconSrc from '../../assets/i/icon-basket-light.png';

const CheckoutProductsList = () => {
    const carts = tools.getPreparedCart();
    return (
        <div id="renderedCheckoutProducts">
            <div className="row">
                {carts.map((merchant) => (
                    <div className="col-md-4 merchant--box--content">
                        <div className="merchant--box">
                            <h5 className="merchant--name">
                                <img src={appConfig.scriptDomainUrl + basketIconSrc} alt="" className="img" />
                                Zamówienie od: {merchant.merchantName}
                            </h5>
                            <ul className="list">
                                {merchant.items.map((item) => (
                                    <li className="item">
                                        <strong className="brand">{item.brand}</strong> - {item.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckoutProductsList;
