import {appendMarketPlaceStylesTag} from "./tools";
import createElement from "./createElement";
import App from "./App";
import appGlobals from "./globals";
import appConfig from "./config";


// function runApplication() {
//     window.document.getElementById(appGlobals.appId).replaceWith(App);
// };
//
// runApplication();