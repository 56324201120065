import { createElement } from "../createElement";
import { helpers, preloader, tools } from "../tools";
import payuIconSrc from '../assets/i/payu-white.png';
import appGlobals from "../globals";
import api from "../api";
import appConfig from "../config";
import WarningBox from "../Common/WarrningBox";
import marketPlaceAnalytics from "../analytics";
import * as util from "util";
import { v4 as uuidv4 } from 'uuid';
import { parse } from "path";



const handleOnChangeNextStep = (step) => {
    event.preventDefault();
    const form = document.getElementById('checkoutForm');
    const nextStep = step + 1;
    const stepBox = document.getElementById('preOrderStep-' + step);
    const stepNextBox = document.getElementById('preOrderStep-' + nextStep);
    const formValidation = helpers.validateForm(form);
    if (formValidation.isValid) {
        formatInputs();
        autocompleteUserFieldsData(form);

        // re-validate visibility inputs
        const autocompleteInvoiceDetails = document.getElementById('autocompleteInvoiceDetails');
        const autocompleteInpostDataDetails = document.getElementById('autocompleteInpostDataDetails');

        const isInvoiceInput = document.getElementById('isInvoiceInput');
        if ((isInvoiceInput && isInvoiceInput.checked) && autocompleteInvoiceDetails) {
            autocompleteInvoiceDetails.style.display = 'block';
        }


        if (autocompleteInpostDataDetails) {
            if (tools.hasGeowidgetShippingMethod()) {
                autocompleteInpostDataDetails.style.display = 'block'
                // uzupellnij!
                if (appGlobals.selectedInpostData != null) {
                    setTimeout(() => {
                        document.querySelector('[data-autocomplete-name="inpostName"]').innerHTML = appGlobals.selectedInpostData.name;
                        document.querySelector('[data-autocomplete-name="inpostAddress1"]').innerHTML = appGlobals.selectedInpostData.address.line1;
                        document.querySelector('[data-autocomplete-name="inpostAddress2"]').innerHTML = appGlobals.selectedInpostData.address.line2;
                    }, 100)
                }

            } else {
                autocompleteInpostDataDetails.style.display = 'none';
            }
        }

        tools.renderSummaryMerchantListCheckout();

        if (stepBox) {
            stepBox.style.display = 'none';
        }

        if (stepNextBox) {
            stepNextBox.style.display = 'block';
        }
        tools.scrollToTop();

        /**
         * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.PersonalDataProvided, {}, tools.getCart());
    } else {
        tools.scrollToError();
    }
    appGlobals.cartUserDetails = formValidation.details;

};

const formatInputs = () => {
    const numberInput = document.querySelector('[data-name="userPhone"]');
    const nipInput = document.querySelector('[data-name="nip"]');
    const formatedNb = numberInput.value.replace(/(\d{3})(\d{3})(\d{3})/, '$1$2$3');
    const formatedNip = nipInput.value.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1$2$3$4');
    numberInput.value = formatedNb;
    nipInput.value = formatedNip;
}

const autocompleteUserFieldsData = (form) => {
    const autoCompleteElements = form.querySelectorAll('[data-name]');
    autoCompleteElements.forEach(input => {
        let name = input.getAttribute('data-name');
        let focusAutocompleteTextInputs = document.querySelectorAll('[data-autocomplete-name="' + name + '"]');
        focusAutocompleteTextInputs.forEach(focusAutocompleteText => {
            if (input.getAttribute('type') == 'radio') {
                if (input.checked) {
                    let deliveryMethodObject = input.getAttribute('data-delivery-method');
                    if (deliveryMethodObject) {
                        deliveryMethodObject = JSON.parse(deliveryMethodObject);
                        focusAutocompleteText.querySelector('img').src = deliveryMethodObject.imageUrl;
                    }
                    // focusAutocompleteText.querySelector('img').src = `/dist/app/assets/i/logo-${input.value.toLowerCase()}.png`;
                }
            } else {
                if (focusAutocompleteText) {
                    focusAutocompleteText.innerHTML = input.value;
                }
            }
        })

    })
};

const handleOnClickPaymentGo = async (e) => {
    //validate additional checkboxes

    let isAllowedProceed = true;
    document.querySelectorAll('.merchantCheckboxAgreement').forEach(input => {
        helpers.validateInputField(input, true);
        if (!input.checked) {
            isAllowedProceed = false;
        }
    })

    if (!isAllowedProceed) {
        return false;
    }

    e.preventDefault();
    let formDetails = {
        buyerAddress: {
            companyName: '',
            streetName: '',
            houseNumber: '',
            flatNumber: null,
            postalCode: '',
            city: '',
            country: '',
        },
        shippingAddress: {
            companyName: '',
            streetName: '',
            houseNumber: '',
            flatNumber: null,
            postalCode: '',
            city: '',
            country: '',
            targetPoint: ''
        },
        invoiceAddress: {
            companyName: '',
            streetName: '',
            houseNumber: '',
            flatNumber: null,
            postalCode: '',
            city: '',
            country: '',
        },
        carts: tools.getPreparedCart(),
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        nip: '',
        remarks: '',
        guid: helpers.getGuid(),
        totalShippingCost: parseFloat(tools.getPreparedCartDeliveryPrice().toFixed(2)),
        totalShippingCostCurrency: tools.getDefaultProductsCurrency(),
        totalPrice: parseFloat(tools.getPreparedCartTotalPrice(true).toFixed(2)),
        totalPriceCurrency: tools.getDefaultProductsCurrency(),
        websiteId: appConfig.websiteId
    };

    //global data
    let hasInvoice = document.querySelector('input[name="cart_is_invoice"]').checked;
    // all data inputs
    document.querySelectorAll('[data-api-name]').forEach(input => {
        let apiFieldName = input.getAttribute('data-api-name');
        let apiFieldGroupName = input.getAttribute('data-api-field');
        let apiFieldGroupNameForce = input.getAttribute('data-api-field-group');
        let value = input.value ? input.value : null;

        if (value && value == 'POLSKA') {
            value = 'PL';
        }

        if (value) {
            value = value.trim();
        }

        if (apiFieldGroupName === 'invoiceAddress' && !hasInvoice) {
            return false;
        }

        if (apiFieldGroupName) {
            if (apiFieldGroupNameForce) {
                formDetails[apiFieldName] = value;
            } else {
                formDetails[apiFieldGroupName][apiFieldName] = value;
            }
        } else {
            formDetails[apiFieldName] = value;
        }
    });

    document.querySelectorAll('[data-courier-name]').forEach(input => {
        let courierDataName = input.getAttribute('data-courier-name');
        let value = input.value ? input.value : null;

        if (value && value == 'POLSKA') {
            value = 'PL';
        }

        if (value) {
            value = value.trim();
        }

        if (courierDataName) {
            formDetails.buyerAddress[courierDataName] = value;
        }
    });

    if (!formDetails.buyerAddress.city || !formDetails.buyerAddress.postalCode) {
        formDetails.buyerAddress = null;
    }

    //geowidget
    if (appGlobals.selectedInpostData && formDetails.shippingAddress) {
        formDetails.shippingAddress = tools.getSelectedShippingDataInpost();
    } else {
        formDetails.shippingAddress = null;
    }

    if (!hasInvoice) {
        formDetails.invoiceAddress = null;
    }

    // dodanie shippingCost i shippingCostCurrency do carts
    formDetails.carts.map(cart => {
        const shippingMethod = tools.getMerchantSelectedShippingMethod(cart.merchantId);
        cart.shippingCost = shippingMethod.cost;
        cart.shippingCostCurrency = shippingMethod.currency;
        cart.items.map(item => item.price = parseFloat((item.price * item.selectedQuantity).toFixed(2)));

        cart.items.map(item => {
            delete item.imageWidth;
            delete item.imageHeight;
            delete item.availableQuantity;
            delete item.priceText;
            delete item.imageUrl;
        })
        delete cart.shippingMethods;
    })


    /**
     * Changing sended field names and deletes old
     */
    delete Object.assign(formDetails,
        {
            courierDeliveryAddress: formDetails.buyerAddress,
            parcelLockerAddress: formDetails.shippingAddress,
            billingAddress: formDetails.invoiceAddress

        })
    ["buyerAddress"],
        ['shippingAddress'],
        ['invoiceAddress'];

    preloader.show();
    document.getElementById('zamawiam').disabled = true;
    const response = await api.sendOrder(formDetails);

    if (response && response.result === 'REDIRECT' && response.redirectUrl) {

        /**
         * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ProductsOrdered,  {}, formDetails.products);

        tools.clearCart();
        preloader.hide();
        window.location.href = response.redirectUrl;
    } else if (response && (response.result == 'ERROR' || response.result == 'WARNING' || response.result == 'INFO' || response.result == 'UNAVAILABLE')) {
        document.getElementById('zamawiam').disabled = false;
        preloader.hide();
        let box = WarningBox(response.result, response.message);
        if (response.result == 'UNAVAILABLE') {
            response.carts = response.carts === undefined ? '' : response.carts;
            box = WarningBox(response.result, response.message, response.carts);
        }
        document.getElementById('warningLayer').append(box)
    }
};

const CheckoutSumupBox = (step) => {
    let totalPrice = tools.getCartTotalPrice();
    const preparedCart = tools.getPreparedCart();
    const button = step === 1 ?
        (<button className="btn--dark -md _w-100 text-uppercase _mobile-radius-0"
            onClick={() => handleOnChangeNextStep(1)}>Do
            Kasy</button>) :
        (<button id="zamawiam" className="btn--dark -md _w-100 text-uppercase _mobile-radius-0"
            onClick={handleOnClickPaymentGo}>Zamawiam i płacę</button>);
    // (
    //
    //         appGlobals.paymentMethods.map(paymentMethod => {
    //             return (
    //                 <button className="btn--go--checkout _w-100 mb-3" onClick={handleOnClickPaymentGo} data-paymentmethod-id={paymentMethod.id}><img src={paymentMethod.imageUrl} className="icon" />{paymentMethod.name} </button>
    //             )
    //         })
    //     );

    const handleSelectAllCheckbox = () => {
        let merchantAgreements = document.querySelectorAll('.merchantCheckboxAgreement');
        if (merchantAgreements) {
            merchantAgreements.forEach(el => {
                el.checked = event.target.checked;
                el.dispatchEvent(new Event('change'));
            })
        }
    }

    const checkboxRules =
        (
            preparedCart.map(merchant => (
                <div className="checkbox--wrapper -rules -sm">
                    <label className="label">
                        <input type="checkbox"
                            name={`cart_accept_rules_${merchant.merchantId}`}
                            className="checkbox_agreement checkbox merchantCheckboxAgreement"
                        />
                        <span className="checkmark"></span>
                        <span className="text">Akceptuję regulamin <a
                            href="https://akcesoriameskie.pl/pl/i/Polityka-prywatnosci/30"
                            target="_blank"><u>{merchant.merchantName}</u></a></span>
                    </label>
                </div>
            ))
        );

    const checkboxAllRules = (
        <div className="checkbox--wrapper -rules -sm">
            <label className="label">
                <input type="checkbox"
                    name="check_all"
                    className="checkbox_agreement checkbox"
                    onChange={handleSelectAllCheckbox}
                />
                <span className="checkmark"></span>
                <span className="text">Akceptuję regulaminy</span>
            </label>
        </div>
    );

    const rulesCheckboxes = step === 2 ? (
        <div className="rules--checkboxes">
            {checkboxAllRules}
            <div className="checkbox--wrapper -rules -sm">
                <label className="label">
                    <input type="checkbox"
                        name={`cart_accept_rules_iiTako`}
                        className="checkbox_agreement checkbox merchantCheckboxAgreement"
                    />
                    <span className="checkmark"></span>
                    <span className="text">Akceptuję regulamin <a
                        href="https://docs.iine.eu/legal/iiTako_marketplace_Terms_and_Regulations.pdf"
                        target="_blank"><u>iiTako Marketplace</u></a></span>
                </label>
            </div>
            {checkboxRules}
        </div>

    ):'';

    return (
        <div className="checkoutSummaryBox">
            <h3 className="cart--summary--title">Podsumowanie zamówienia</h3>
            <div className="cart--summary--info">Łącznie <span
                className="autocompleteCartPriceText">{helpers.setFormatNumberString(totalPrice)}</span></div>
            <div className="cart--summary--info">Dostawa <span className="autocompleteDeliveryPriceText"></span></div>
            <div className="cart--summary--info">
                <div><b>Do zapłaty</b>
                    <div className="vat">(w tym VAT)</div>
                </div>
                <strong className="autocompleteTotalPriceText">{helpers.setFormatNumberString(totalPrice)}</strong>
            </div>

            {button}
            {rulesCheckboxes}
        </div>
    )
}

export default CheckoutSumupBox;
