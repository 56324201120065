import { createElement } from "../../createElement";
import { helpers, tools } from "../../tools";
import appConfig from "../../config";
import CartProductItem from "../CartProductItem";
import marketPlaceAnalytics from "../../analytics";
import basketIconSrc from '../../assets/i/basket-icon.png';
import courierIconSrc from '../../assets/i/icon-courier.png';
import appGlobals from "../../globals";

const MerchantItem = (merchantItem) => {
    const { merchant } = merchantItem;

    const handleOnChangeDeliveryMethod = (shippingMethod, e) => {
        e.target.closest('.delivery--types--container').classList.remove('-error');
        merchant.shippingMethod = shippingMethod.id;
        let deliveryMerchantPriceContainer = document.querySelector('.merchantDeliveryPrice[data-merchant="' + merchant.merchantId + '"]');
        if (deliveryMerchantPriceContainer) {
            deliveryMerchantPriceContainer.innerHTML = '';
            deliveryMerchantPriceContainer.append(helpers.setFormatNumberString(shippingMethod.cost));
        }

        appGlobals.selectedShippingMethods[merchant.merchantId] = shippingMethod;

        tools.updateSumUpBoxCartPrice();

        /**
         * * Call analytics events
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.ShipmentProviderChosen, { selectedShipmentProvider: shippingMethod.name }, tools.getCart());
    }

    const filterCheckedInputs = () => {
        let elements = document.querySelectorAll('input[data-merchant="' + merchant.merchantId + '"]');
        if (appGlobals.selectedShippingMethods[merchant.merchantId]) {
            merchant.shippingMethod = appGlobals.selectedShippingMethods[merchant.merchantId].id;
        }


        elements.forEach((element) => {
            if (element.value == merchant.shippingMethod) {
                element.checked = true;
                //dispatch event to auto-refresh delivery cost
                element.dispatchEvent(new Event('change'));
            }
        })
    }

    const handleDeliveriesPosition = () => {
        const containers = document.querySelectorAll('.delivery--types--container');

        Array.from(containers).map(container => {
            const deliveries = container.querySelectorAll('.input--radio--component');
            Array.from(deliveries).map(delivery => {
                if (delivery.parentElement.offsetTop > 10) {
                    container.classList.add('-break');
                }
            })
        })
    }

    setTimeout(() => {
        filterCheckedInputs();
        handleDeliveriesPosition();

        if(appGlobals.handleAmp){
            appGlobals.selectedShippingMethods[merchant.merchantId] = merchant.shippingMethods.find(item => item.id = appGlobals.selectedShippingMethods[merchant.merchantId]);
            filterCheckedInputs();
            setTimeout(() => {
                let triggerGoToOrder = document.querySelector('#triggerGoToOrder');
                if (triggerGoToOrder) {
                    appGlobals.handleAmp = false;
                    triggerGoToOrder.click();
                }
            },100)
        }
    }, 100)

    return (
        <div className="merchant--item">
            <h5 className="name">
                <img src={appConfig.scriptDomainUrl+basketIconSrc} alt="" className="img" />
                Zakupy w {merchant.merchantName}
            </h5>

            <div className="delivery--types--container">
                <div className="row align-items-center _flex-1">
                    <div className="col-auto">
                        <span className="delivery--type--title">SPOSÓB DOSTAWY</span>
                    </div>
                    <div className="col-md">
                        <div className="row align-items-center">
                            {merchant.shippingMethods.map((shippingMethod) => {
                                return (
                                    <div className="col-auto">
                                        <div className="input--radio--component py-1">
                                            <label htmlFor={'cart_delivery_method_' + merchant.merchantId}
                                                className="radio--label mb-md-0">
                                                    {shippingMethod.imageUrl ? (<img src={shippingMethod.imageUrl}
                                                    className="inpost--logo" />) : (<img src={appConfig.scriptDomainUrl+courierIconSrc}
                                                    className="inpost--logo -courier" />)}

                                                <span className="text">{shippingMethod.name}</span>
                                                <input type="radio"
                                                    name={'cart_delivery_method_' + merchant.merchantId}
                                                    className="input formValidationItem dataShippingItemInput"
                                                    onChange={(e) => handleOnChangeDeliveryMethod(shippingMethod, e)}
                                                    data-required="required"
                                                    data-name="shippingMethodName"
                                                    data-delivery-method={JSON.stringify(shippingMethod)}
                                                    data-merchant={merchant.merchantId}
                                                    data-shipping={shippingMethod.id}
                                                    value={shippingMethod.id}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <div className="col-auto ml-auto">
                        <strong className="delivery--type--price merchantDeliveryPrice" data-merchant={merchant.merchantId}>{helpers.setFormatNumberString(0)}</strong>
                    </div>
                </div>
            </div>

            {merchant.items.map((product) => (
                <CartProductItem product={product} />
            ))}
        </div>
    )
};

export default MerchantItem;
