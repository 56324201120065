import {createElement} from "../createElement";
import CheckoutStepForm from "./Steps/CheckoutStepForm";
import CheckoutStepSumup from "./Steps/CheckoutStepSumup";
import CheckoutHeader from "./CheckoutHeader";
import {helpers} from "../tools";

const CheckoutForm = () => {
    setTimeout(() =>{
        let form = document.getElementById('checkoutForm');
        helpers.setupApiValidateFields(form);
    },500)
    return (
        <div id="checkoutFormWrapper">
            <form id="checkoutForm" onSubmit={(e) => {e.preventDefault()} }>
                {/* STEP 1 ( DATA ADDRESS )*/}
                <div id="preOrderStep-1" className="pre--order--step -step-1">
                    <CheckoutHeader currentStep="dostawa" />
                    <div id="checkoutStepFormContainer">
                        {CheckoutStepForm()}
                    </div>
                </div>

                {/* STEP 2 ( PAYMENT )*/}
                <div id="preOrderStep-2" className="pre--order--step -step-2" style="display:none">
                    <CheckoutHeader currentStep="płatność" />
                    <div id="checkoutSummUpContainer">
                        {CheckoutStepSumup()}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CheckoutForm;