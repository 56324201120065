import appConfig from "./config";
import moment from 'moment';
import {IineFingerprint} from "./assets/libs/fingerprint";

const marketPlaceAnalytics = {
    currentProductsList: null,
    alreadySawWidgets: {},
    triggeredNestedScrollLevel: {},
    eventName: null,
    eventType: null,
    additionalData: null,
    eventTypes: {
        ProductDetailsViewed: 'ProductDetailsViewed',
        ProductVariantChanged: 'ProductVariantChanged',
        ProductAdded: 'ProductAdded',
        ProductRemoved: 'ProductRemoved',
        ProductsOrdered: 'ProductsOrdered',
        WidgetInit: 'WidgetInit',
        WidgetHover: 'WidgetHover',
        WidgetImpression: 'WidgetImpression',
        WidgetRenewedImpression: 'WidgetRenewedImpression',
        WidgetSwiped: 'WidgetSwiped',
        WidgetOpen: 'WidgetOpen',
        WidgetClosed: 'WidgetClosed',
        PaymentMethodChosen: 'PaymentMethodChosen',
        PersonalDataProvided: 'PersonalDataProvided',
        ShipmentProviderChosen: 'ShipmentProviderChosen',
        WidgetsVisibilityToggled: 'WidgetsVisibilityToggled',
        ProductImageChanged: 'ProductImageChanged',
        SingleProductQuantityChanged: 'SingleProductQuantityChanged',
        ChartOpened: 'ChartOpened',
        WidgetProductModalImpression: 'WidgetProductModalImpression',
    },
    eventsListToShare: [],

    prepareObject: function(eventType, eventData = {}, productData = []){
        var object = {
            eventType: eventType,
            fingerprint: marketPlaceAnalytics.getUserDataFingerPrint().fingerprint,
            timestamp: moment().format(),
            eventData: {
                eventSource: window.location.href,
                widgetId: null,
                websiteId: appConfig.websiteId,
            },
            productData: []
        };

        if(eventData){
            object.eventData = {...object.eventData, ...eventData}
        }

        if(productData){
            object.productData = productData;
        }


        // switch (eventType) {
        //     case analytics.eventTypes.ProductViewed:
        //     case analytics.eventTypes.ProductsListAdd:
        //     case analytics.eventTypes.ProductsListRemove:
        //         object.ProductData = data;
        //         break;
        //     case analytics.eventTypes.PagesDownloaded:
        //     case analytics.eventTypes.PagesPrinted:
        //         object.PageNumbers = data;
        //         break;
        //     case analytics.eventTypes.ProductsListEmailed:
        //     case analytics.eventTypes.ProductsListSharedWhatsApp:
        //     case analytics.eventTypes.ProductsListCleared:
        //     case analytics.eventTypes.ProductsListCopied:
        //         object.ProductData = utils.getPreOrderListIds();
        //         break;
        //     case analytics.eventTypes.PageViewed:
        //         object.ViewerData.CurrentPage = data;
        //         break;
        //     case analytics.eventTypes.PageCropShared:
        //     case analytics.eventTypes.PageShared:
        //         object.shareType = data;
        //         break;
        //     case analytics.eventTypes.PageBookmarked:
        //         object.pageNumbers = data;
        //         break;
        // }
        //
        // if(analytics.eventTypes.PageViewed){
        //     object.pageNumbers = data;
        // }


        return object;
    },

    sendAnalyticsData: function (eventType, eventData, productData) {
        let analyticsObject = marketPlaceAnalytics.prepareObject(eventType, eventData, productData);
        marketPlaceAnalytics.eventsListToShare.push(analyticsObject);

        // console.log(marketPlaceAnalytics.eventsListToShare);

        if (marketPlaceAnalytics.eventsListToShare.length > 10) {
            // console.log('here fire submit');
            marketPlaceAnalytics.submitAnalyticsDataRequest();
        }
    },

    submitAnalyticsDataRequest: function (async = true) {
        $.ajax({
            url: 'https://api.marketplace.iine.eu/api/v1/analytics/events',
            dataType: "json",
            async: async,
            contentType: "application/json;charset=utf-8",
            type: 'POST',
            data: JSON.stringify(marketPlaceAnalytics.eventsListToShare),
            success: function (data) {
            },
            error: function (data) {
            },
            complete: function () {
            }
        });
        marketPlaceAnalytics.eventsListToShare.splice(0,10);
    },

    getUserDataFingerPrint: function () {
        const fingerPrint = new IineFingerprint();
        return fingerPrint.getFingerprintData();
        // return localStorage.getItem("iineUserData") ? JSON.parse(localStorage.getItem("iineUserData")) : null;
    },

    getOperatingSystem: function () {
        return window.navigator.platform;
    },

    getUserAgent: function () {
        return window.navigator.userAgent;
    }
}


export default marketPlaceAnalytics;