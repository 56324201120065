import {createElement} from "../../createElement";
import {tools} from "../../tools";
import appConfig from "../../config";
import MerchantItem from "./MerchantItem";


const MerchantList = () => {
    const merchants = appGlobals.preparedCart;
    return (
        <div id="merchantsList" className="merchant--list">
            {merchants.map((merchantItem) => (
                <MerchantItem merchant={merchantItem}/>
            ))}
        </div>
    )
};

export default MerchantList;