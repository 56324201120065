import {createElement} from "../createElement";
import appConfig from "../config";
import iconCloseSrc from "../assets/i/close-button.png";
import appGlobals from "../globals";
import {customModal, tools} from "../tools";

const CloseButton = () => {
    const handleCloseButton = (e) => {
        e.preventDefault();
        // tools.deleteMarketPlaceStylesTag(appGlobals.marketPlaceMainStyles);
        customModal.hide();
    };

    return (
        <div className="button--close--modal">
            <span id="triggerCloseModal" className="close--modal--button triggerCloseModal" onClick={ () => {handleCloseButton(event)}}>
                <img src={appConfig.scriptDomainUrl + iconCloseSrc} className="img-fluid"/>
            </span>
        </div>
    )
};

export default CloseButton;