import {createElement} from "../createElement";
import {helpers, tools} from "../tools";
import ProductDetailContent from "./ProductDetailContent";
import api from "../api";
import appGlobals from "../globals";
import appConfig from "../config";
import noImageSrc from "../assets/i/no-image.png";

const handleOnClickProduct = async (product,widgetId) => {
    if(widgetId){
        appGlobals.activeWidgetIndex = widgetId;
    }
    const productItem = await api.getProduct(product.id);
    tools.setupProductLayer(productItem)
};

function ProductItem(props) {
    const {product} = props;
    const {widgetId} = props;

    return (
        <div className="col-md-4 col-6 singleProductItem">
            <div className="product--img"><img className="img" src={product.imageUrl || appConfig.scriptDomainUrl+noImageSrc} alt="" onClick={() => handleOnClickProduct(product,widgetId)} onError={(e) => e.target.src = appConfig.scriptDomainUrl+noImageSrc} /></div>
            <div className="product--description--box">
                <h3 className="brand--name" onClick={() => handleOnClickProduct(product)}>{product.brand}</h3>
                <div className="product--description">{product.name}</div>
                {/*{!widgetId ? (*/}
                {/*    <div className="product--id">ID {product.sku}</div>*/}
                {/*):''}*/}
                <h2 className={`product--price ${product.oldPrice ? '-highlighted' : '' } `}>
                    {helpers.setFormatNumberString(product.price, product.currency)}
                    {product.oldPrice ? (
                        <span className="old">{helpers.setFormatNumberString(product.oldPrice, product.currency)}</span>
                    ) : ''}
                </h2>
            </div>
        </div>
    )
}

export default ProductItem;