import {createElement} from "../createElement";
import {helpers, tools} from "../tools";
import api from "../api";
import appGlobals from "../globals";
import appConfig from "../config";
import ProductDetailContent from "../Product/ProductDetailContent";
import noImageSrc from "../assets/i/no-image.png";

const handleOnClickProduct = async (product, widgetId) => {
    if(appGlobals.blockApiCalls){
        return false;
    }

    if (widgetId >=0) {
        appGlobals.activeWidgetIndex = widgetId;
    }
    const productItem = await api.getProduct(product.id);
    tools.setupProductLayer(productItem)
};

function WidgetProductItem(props) {
    const {product} = props;
    const {widgetId} = props;
    const {itemConfig} = props;

    return (
        <div className="widget--carousel--product--item d-flex justify-content-left" style={'width:' + itemConfig.cellWidth + 'px'}>
            <div className="product--wrapper d-flex flex-column">
                <div className="product--image d-flex align-items-center justify-content-center">
                    <img style={'max-height:' + itemConfig.imageHeight + 'px'} className="img" src={product.imageUrl || appConfig.scriptDomainUrl+noImageSrc} onError={(e) => e.target.src = appConfig.scriptDomainUrl+noImageSrc} alt="" onClick={() => handleOnClickProduct(product, widgetId)}/>
                </div>
                <div className="product--description--box">
                    <h3 className="brand--name" onClick={() => handleOnClickProduct(product)}>{product.brand}</h3>
                    <div className="product--description">{product.name}</div>
                    {/*{!widgetId ? (*/}
                    {/*    <div className="product--id">ID {product.sku}</div>*/}
                    {/*) : ''}*/}
                    <h2 className={`product--price ${product.oldPrice ? '-highlighted' : ''} `}>
                        {helpers.setFormatNumberString(product.price, product.currency)}
                        {product.oldPrice ? (
                            <span className="old">{helpers.setFormatNumberString(product.oldPrice, product.currency)}</span>
                        ) : ''}
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default WidgetProductItem;