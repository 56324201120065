import {createElement} from "../createElement";
import {helpers, tools} from "../tools";
import plusIconSrc from '../assets/i/icon-plus.png';
import minusIconSrc from '../assets/i/icon-minus.png';
import appConfig from "../config";
import marketPlaceAnalytics from "../analytics";
import noImageSrc from "../assets/i/no-image.png";

const TYPE_INCREMENT = 'INCREMENT';
const TYPE_DECREMENT = 'DECREMENT';

const handleDeleteProduct = (product) => {
    tools.removeProductFromCart(product.id);
    tools.updateCartList();
    tools.updateCartLength();
    tools.refreshMerchantShipping(product.id);
};

const handleUpdateProductQuantity = (product, action, newQuantity) => {
    const index = tools.getCartProductIndexByHash(product.id);
    const renderedQuantity = document.getElementsByName('productQuantity_'+product.id)[0];
    const renderedPrice = document.getElementsByName('productPrice_'+product.id)[0];
    const renderedOldPrice = document.getElementsByName('productOldPrice_'+product.id)[0];
    if (index < 0) {
        return false;
    }
    const cart = tools.getCart();
    // console.log(product);
    // let variant = product.variants.find(variant => {
    //     return variant.color === product.color && variant.size === product.size;
    // });

    if (action == TYPE_INCREMENT) {
        cart[index].selectedQuantity += 1;
    }
    if (action == TYPE_DECREMENT) {
        cart[index].selectedQuantity -= 1;
    }
    if (newQuantity) {
        cart[index].selectedQuantity = newQuantity;
    }

    // replace quantity to availableQuantity
    if(cart[index].availableQuantity < cart[index].selectedQuantity){
        return false;
    }


    if (cart[index].selectedQuantity <= 0) {
        handleDeleteProduct(product);
        /**
         * Call analytics events / Quantity change
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.SingleProductQuantityChanged,  {}, [product]);
    } else {
        tools.setCart(cart);
        tools.refreshMerchantShipping(product.id);

        renderedQuantity.value = cart[index].selectedQuantity;
        renderedPrice.firstChild.replaceWith(helpers.setFormatNumberString(cart[index].price * cart[index].selectedQuantity)), 
        /**
         * Call analytics events / Quantity change
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.SingleProductQuantityChanged,  {}, [cart[index]]);
    }

    tools.updateCartLength();
};


function CartProductItem(props) {
    const {product} = props;
    const {isSumupOrder} = props;
    const productImage = product.imageUrl ? product.imageUrl : product.images ? product.images[0].thumbnail: '';

    return (
        <div className="cart--product--item">
            <div className="product--image">
                <img src={productImage || appConfig.scriptDomainUrl+noImageSrc} onError={(e) => e.target.src = appConfig.scriptDomainUrl+noImageSrc} alt="" className="img" />
            </div>
            <div className="details">
                <div className="cart--product--info pt-1">
                    <h3 className="brand--name">{product.brand}</h3>
                    <div className="product--description">{product.name}</div>
                </div>
                <div className="cart--product--details">
                    <div className="cart--label d-none d-md-block">Szczegóły</div>
                    {product.color ? (
                        <div className="cart--detail--info">
                            Kolor: <strong>{product.color}</strong>
                        </div>
                    ):''}
                    {product.size ? (
                        <div className="cart--detail--info mb-md-0">
                            Rozmiar: <strong>{product.size}</strong>
                        </div>
                    ):''}
                </div>
                <div className="cart--product--quantity">
                    <div className="cart--label mb-0 mb-md-1 d-none d-md-block">Ilość</div>
                    {!isSumupOrder ? (
                        <div className="product--quantity--buttons d-flex justify-content-between align-items-center">
                        <span className="d-md-none cart--detail--info m-0">Ilość: </span>
                        <button className="quantity--button--icon"
                            onClick={() => {
                                handleUpdateProductQuantity(product, TYPE_DECREMENT)
                            }}
                        ><img src={appConfig.scriptDomainUrl+minusIconSrc} className="img-fluid" /></button>
                            <input className="input--number" name={'productQuantity_'+product.id} type="number" value={product.selectedQuantity}
                                // onChange={() => {
                                //     handleUpdateProductQuantity(product, null, event.target.value)
                                // }}
                                disabled="disabled"
                            />
                            <button className="quantity--button--icon"
                                onClick={() => {
                                    handleUpdateProductQuantity(product, TYPE_INCREMENT)
                                }}
                            ><img src={appConfig.scriptDomainUrl+plusIconSrc} className="img-fluid" /></button>
                        </div>
                    ) : (
                        <div className="cart--detail--info">
                            <span className="d-md-none">Ilość: </span><strong class="-light">{product.selectedQuantity}</strong>
                        </div>
                    )}
                </div>
                <div className="cart--product--summary">
                    <div className="cart--label mb-1 d-none d-md-block">Razem</div>
                    <div className={`product--price ${product.oldPrice && '-highlighted'}`}>
                       <div name={'productPrice_'+product.id}>{helpers.setFormatNumberString(product.price * product.selectedQuantity)}</div> 
                        {product.oldPrice ? (
                            <span name={'productOldPrice_'+product.id} className="old">{helpers.setFormatNumberString(product.oldPrice * product.selectedQuantity, product.currency)}</span>) : ''}
                    </div>
                </div>
                <div className="cart--product--info">
                    {!isSumupOrder ? (
                        <button className="btn--light"
                                onClick={() => {
                                    handleDeleteProduct(product)
                                }}
                        >Usuń
                        </button>
                    ) : ('')}
                </div>
            </div>

        </div>
    )
}

export default CartProductItem;
