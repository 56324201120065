import {createElement} from "../../createElement";
import arrowSrc from "../../assets/i/arrow-carousel.png";
import appConfig from "../../config";
import minusIconSrc from "../../assets/i/icon-minus.png";


const ProductDetailsDescription = (product) => {
    const handleDescriptionToggler = (e) => {
        if (e.classList.contains('-hidden')) {
            e.classList.remove('-hidden');
            e.nextSibling.style.display = '';
        } else {
            e.classList.add('-hidden');
            e.nextSibling.style.display = 'none';
        }
    };

    const parseString = (string) => {
        const htmlElem = document.createElement('span');
        htmlElem.innerHTML = string;
        return htmlElem;
    }

    return(
        <div className="productdescription" id="productDetailsDescription">
            <button type="button" className="product--description -btn" onClick={() => {
                handleDescriptionToggler(event.currentTarget)
            }}>Opis <img src={appConfig.scriptDomainUrl+arrowSrc} className="arrow"/></button>
            <div className="product--description -light -detail" data-scrollbar>{parseString(product.description)}</div>
        </div>
    )
};

export default ProductDetailsDescription;