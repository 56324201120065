import {createElement} from "../createElement";
import {tools} from "../tools";
import CartContent from "./CartContent";
import basketIconSrc from '../assets/i/icon-basket.png';
import appConfig from "../config";
import minusIconSrc from "../assets/i/icon-minus.png";
import api from "../api";

const handleOnClickHeaderCart =  () => {
    const cartLayer = CartContent();
    tools.setPopupLayer('cartLayer', cartLayer);
    tools.scrollToTop();
};

const HeaderCart = (
    <div>
        <div className="header--cart--component" onClick={handleOnClickHeaderCart}>
            <img className="icon--basket" src={appConfig.scriptDomainUrl+basketIconSrc} /><i className="cart--icon"/> Twój koszyk (<span className="cartLengthNumber">0</span>)
        </div>
    </div>
);


export default HeaderCart;