import {createElement} from "../../createElement";
import {helpers} from "../../tools";

const ProductDetailsName = (product) => {
    return (
        <div id="productDetailsName" className="product--details--data">
            <h2 className="brand--name">{product.brand}</h2>
            <div className="product--description">{product.name}</div>
            {/*<div className="product--id -detail">ID: {product.sku}</div>*/}
            <h2 className={`product--price ${product.oldPrice ? '-highlighted' : ''} `}>
                {helpers.setFormatNumberString(product.price, product.currency)}
                {product.oldPrice ? (
                    <span className="old">{helpers.setFormatNumberString(product.oldPrice, product.currency)}</span>
                ) : ''}
            </h2>
        </div>
    )
}

export default ProductDetailsName;