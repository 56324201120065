import { createElement } from "./createElement";
import appGlobals from "./globals";
import ProductList from "./Product/ProductList";
import HeaderCart from "./Cart/HeaderCart";
import logoSrc from './assets/i/logo.png';
import iconCloseSrc from './assets/i/btn-close.png';
import appConfig from "./config";
import minusIconSrc from "./assets/i/icon-minus.png";
import WarningBox from "./Common/WarrningBox";

const App = (
    <div id={appGlobals.appId} className="marketPlaceApp marketplace--application" style="display:none;">
        <div className="marketPlacePopup marketplace--popup modal--screen--component" id="marketPlaceModal">
            {/*<span id="triggerCloseModal" className="close--btn triggerCloseModal"><img src={appConfig.scriptDomainUrl+iconCloseSrc} className="img-fluid" /></span>*/}

            <div id="modalLayer" className="modal--layers marketplaceContainerLayers">
                {/*Product List  Layer*/}
                <div id="productListLayer" className="single--layer-app productListContainer"></div>

                {/*Product Detail Layer*/}
                <div id="productDetailLayer" className="single--layer-app productDetailContainer"></div>

                {/*Cart Layer*/}
                <div id="cartLayer" className="single--layer-app cartLayer"></div>

                {/*Cart Layer*/}
                <div id="checkoutLayer" className="single--layer-app checkoutLayer"></div>

                <div id="warningLayer"></div>
            </div>

            <div className="modal--footer">
                <span className="powered--by">Powered by <a href="https://iine.io/" className="url" target="_blank"><img className="img" src={appConfig.scriptDomainUrl + logoSrc} /></a></span>
            </div>
        </div>
    </div>
);

export default App;