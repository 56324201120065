import {createElement} from "../../createElement";
import appConfig from "../../config";
import noImageSrc from "../../assets/i/no-image.png";

const ProductDetailCarousel = (product) => {

    return (
        <div id="productCarouselContainer" className="product--carousel--container">
            <div className="product--carousel--thumbs productDetailCarouselThumbs">
                {product && product.images && product.images.map((image) => {
                        if (image && image.thumbnail) {
                            return (
                                <div>
                                    <div className="product--carousel--thumb"><img src={image.thumbnail}/></div>
                                </div>
                            )
                        }
                    }
                )}
            </div>
            <div className="product--carousel">
                <div className="productDetailCarousel" id="productDetailCarousel">
                    {product && product.images && product.images.map((image) => {
                            if (image && image.fullSize) {
                                return (
                                    <div>
                                        <div className="product--carousel--item productItemCarousel" style="display: none;">
                                            <div className="zoom" style={`background-image: url(${image.zoomSize});`}>
                                                <img src={image.fullSize || appConfig.scriptDomainUrl+noImageSrc} onError={(e) => e.target.src = appConfig.scriptDomainUrl+noImageSrc} className="img"/>    
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProductDetailCarousel;
