import {createElement} from "../../../createElement";
import {tools} from "../../../tools";
import appConfig from "../../../config";
import MerchantItemSummary from "./MerchantItemSummary";


const MerchantListSummary = () => {
    const merchants = appGlobals.preparedCart;
    return (
        <div id="merchantsListSummary" className="merchant--list">
            {merchants.map((merchantItem) => (
                <MerchantItemSummary merchant={merchantItem}/>
            ))}
        </div>
    )
};

export default MerchantListSummary;