import {createElement} from "../createElement";
import ProductItem from "./ProductItem";
import appGlobals from "../globals";


function ProductList(props){
    const {products} = props;

    return (
        <div className="product--list row">
            {products.map((product) => (
                <ProductItem product={product}/>
            ))}
        </div>
    );
}



export default ProductList;