import {createElement} from "../createElement";
import {tools} from "../tools";
import backIconSrc from '../assets/i/arrow.png';
import appConfig from "../config";
import minusIconSrc from "../assets/i/icon-minus.png";
import CloseButton from "../Common/CloseButton";


const handleStep1Back = () => {
    tools.setActivePopupLayer('cartLayer');
};

const handleStep2Back = () => {
    removePhoneWhiteSpaces();

    const step1Box = document.getElementById('preOrderStep-1');
    const step2Box = document.getElementById('preOrderStep-2');

    step2Box.style.display = 'none';
    step1Box.style.display = 'block';
};

const removePhoneWhiteSpaces = () => {
    const numberInput = document.querySelector('[data-name="userPhone"]');
    const formatedNb = numberInput.value.replace(/ /g,'');
    numberInput.value = formatedNb;
}

const steps = ['dostawa', 'płatność', 'potwierdzenie'];

function CheckoutHeader({currentStep}) {
    return (
        <div>
            <div className="checkout--header--desktop">
                <div className="checkout--header">
                    <div className="btn--back" onClick={currentStep === 'dostawa' ? handleStep1Back : handleStep2Back}>
                        <img className="img-fluid" src={appConfig.scriptDomainUrl + backIconSrc}/>
                    </div>
                    {steps.map( (step,index) => {
                        if (currentStep === step) {
                            return <div className={"step -active step-"+index}>{step}</div>
                        } else {
                            return <div className={"step step-"+index}>{step}</div>
                        }
                    })}
                    <CloseButton/>
                </div>
            </div>
            <div className="checkout--header--mobile">
                <div className="back--navigation d-flex align-items-center justify-content-between">
                    <div className="flex">
                    <div className="btn--back" onClick={currentStep === 'dostawa' ? handleStep1Back : handleStep2Back}>
                        <img className="img-fluid" src={appConfig.scriptDomainUrl + backIconSrc}/>
                    </div>
                        <span className="txt">
                            Składanie zamówienia
                        </span>
                    </div>
                    <CloseButton/>
                </div>
            </div>
            <div className="checkout--header--mobile header--no--fixed">
                <div className="checkout--header">
                    {steps.map(step => {
                        if (currentStep === step) {
                            return <div className="step -active">{step}</div>
                        } else {
                            return <div className="step">{step}</div>
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default CheckoutHeader;
