const appGlobals = {
    appId : 'marketPlaceApplication',
    cart: [],
    preparedProduct: {},
    preparedCart: [],
    availablePaymentMethods: [],
    selectedShippingMethods: {},
    selectedProductPrice: 0,
    handleAmp: false,
    currentSelectedVariants: {},
    renderedTextWidgetProducts: {},
    widgetTypeIinePopup: 'iinePopup',
    widgetTypeIineCarousel: 'iineCarousel',
    widgetTypeIineAnchor: 'iineHotspot',
    widgetTypeIineText: 'iineText',
    marketPlaceMainStyles: 'marketPlaceMainStyles',
    marketPlaceFrontStyles: 'marketPlaceFrontStyles',
    marketPlaceCMSCarouselStyles: 'marketPlaceCmsCarouselStyles',
    cartName: 'marketplace_cart',
    cartMethods :'merchant_shipping_methods',
    handleAmpCartQueryString: 'handleAmp'
};

window.appGlobals = appGlobals;

export default appGlobals;
