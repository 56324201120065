import {createElement} from "../../createElement";
import CheckoutSumupBox from "../CheckoutSumupBox";
import {tools} from "../../tools";
import MerchantListSummary from "../../Cart/Merchants/Summary/MerchantListSummary";
import Scrollbar from 'smooth-scrollbar';

const handleStepBack = (scrollTo) => {
    const step1Box = document.getElementById('preOrderStep-1');
    const step2Box = document.getElementById('preOrderStep-2');

    step2Box.style.display = 'none';
    step1Box.style.display = 'block';

    let element = document.getElementById(scrollTo);
    const scrollbar = Scrollbar.get(document.getElementById('checkoutContainer'));
    if (scrollbar) {
        scrollbar.scrollIntoView(element, {
            alignToTop: true,
            onlyScrollIfNeeded: false,
        });
    }

};

const CheckoutStepSumup = () => {
    return (
        <div id="checkoutSummUpContainer">
            <div className="checkout--container">
                <div className="details" data-scrollbar>
                    <h3 className="header--h3 -uppercase data">Podsumowanie zamówienia</h3>
                    <div className="checkout--separator -summary"></div>
                    <div className="summary--products">
                        <MerchantListSummary/>
                    </div>

                    <div className="sumup--container">
                        <div id="autocompleteBuyerDetails">
                            <div className="summup--box--details">
                                <h3 className="title">Dane kupującego</h3>
                                <div className="sumup--box">
                                    <div>
                                        <span className="capitalize" data-autocomplete-name="userName"></span>&nbsp;
                                        <span className="capitalize" data-autocomplete-name="userSurname"></span>,&nbsp;
                                        email: <strong>
                                        <span data-autocomplete-name="userEmail"></span>
                                    </strong>, &nbsp;
                                        telefon:&nbsp;<strong>
                                        <span data-autocomplete-name="userPhone"></span>
                                    </strong> &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="summup--box--item item col-6 col-md-auto" id="autocompleteInvoiceDetails" style="display:none">
                            <div className="summup--box--details">
                                <h3 className="subtitle">Dane do faktury</h3>
                                <div className="sumup--box">
                                    <div className="autocompletePersonalInvoice" style="display:none">
                                        <span data-autocomplete-name="userName"></span>&nbsp;
                                        <span data-autocomplete-name="userSurname"></span>,&nbsp;
                                    </div>
                                    <div className="autocompleteCompanyInvoice">
                                        <span data-autocomplete-name="companyName"></span>
                                    </div>
                                    <div>ul.&nbsp;<span data-autocomplete-name="invoiceAddressStreet"></span>&nbsp;
                                        <span data-autocomplete-name="invoiceAddressHouseNumber"></span><span className="userInvoiceAddressFlatNumber" style="display: none;">&nbsp;lok.&nbsp;</span>
                                        <span data-autocomplete-name="invoiceAddressFlatNumber"></span>
                                    </div>
                                    <div>
                                        <span data-autocomplete-name="invoicePostcode"></span>&nbsp;
                                        <span data-autocomplete-name="invoiceCity"></span>
                                    </div>
                                    {/* <div data-autocomplete-name="invoiceCountry"></div> */}
                                    <div className="autocompleteCompanyInvoice">
                                        NIP:&nbsp;<span data-autocomplete-name="nip"></span>
                                    </div>
                                </div>
                                <span className="btn--light"
                                      onClick={() => handleStepBack('invoiceData')}>Edytuj dane</span>
                            </div>
                        </div>

                        <div className="summup--box--item item col-6 col-md-auto" id="autocompleteUserDataDetails" style="display:none">
                            <div className="summup--box--details">
                                <h3 className="subtitle">Dane dla kuriera</h3>
                                <div className="sumup--box">
                                    <span className="capitalize" data-autocomplete-name="userDeliveryName"></span> &nbsp;
                                    <span className="capitalize" data-autocomplete-name="userDeliverySurname"></span>
                                    <div data-autocomplete-name="userCompanyName"></div>
                                    <div>
                                    ul.&nbsp;<span data-autocomplete-name="userAddressStreetName"></span>&nbsp;
                                        <span data-autocomplete-name="userAddressHouseNumber"></span><span className="userAddressFlatNumber" style="display: none;">&nbsp;lok.&nbsp;</span>
                                        <span className="userAddressFlatNumber" data-autocomplete-name="userAddressFlatNumber"></span>
                                    </div>
                                    <span data-autocomplete-name="userPostcode"></span>&nbsp;
                                    <span data-autocomplete-name="userCity" className="capitalize"></span>
                                    {/*<div data-autocomplete-name="userCountry"></div>*/}
                                    {/*<div data-autocomplete-name="userPhone"></div>*/}
                                </div>
                                <span className="btn--light" onClick={() => handleStepBack('courierDataContainer')}>Edytuj adres</span>
                            </div>
                        </div>


                        <div className="summup--box--item item col-6 col-md-auto" id="autocompleteInpostDataDetails" style="display:none">
                            <div className="summup--box--details">
                                <h3 className="subtitle">Paczkomat</h3>
                                <div className="sumup--box">
                                    <div>Paczkomat InPost</div>
                                    <div data-autocomplete-name="inpostName"></div>
                                    <div data-autocomplete-name="inpostAddress1"></div>
                                    <div data-autocomplete-name="inpostAddress2"></div>
                                    {/*<span data-autocomplete-name="userName"></span> &nbsp;*/}
                                    {/*<span data-autocomplete-name="userSurname"></span>*/}
                                    {/*<div data-autocomplete-name="userPhone"></div>*/}
                                </div>
                                <span className="btn--light" onClick={() => handleStepBack('deliveryInpostMap')}>Zmień wybór</span>
                            </div>
                        </div>

                        {/*<div className="item">*/}
                        {/*    <div className="summup--box--details">*/}
                        {/*        <h3 className="header--h3">Sposób wysyłki</h3>*/}
                        {/*        <div className="sumup--box">*/}
                        {/*            <span data-autocomplete-name="shippingMethodName"><img src="" className="img-fluid" /></span>*/}
                        {/*        </div>*/}
                        {/*        <span className="btn--light" onClick={handleStepBack}>Zmień sposób wysyłki</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="summary">
                    {CheckoutSumupBox(2)}
                </div>
            </div>
        </div>
    )
}

export default CheckoutStepSumup;