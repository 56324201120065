import {createElement} from "../createElement";
import {tools} from "../tools";
import CartList from "./CartList";
import backIconSrc from '../assets/i/arrow.png';
import appConfig from "../config";
import minusIconSrc from "../assets/i/icon-minus.png";
import CloseButton from "../Common/CloseButton";


const handleOnClickBackButton = () => {
    tools.setActivePopupLayer('productListLayer');
};


const CartContent = () => {
    return (
        <div id="cartLayer" className="single--layer-app cartLayer">
            <div className="app--header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="app--header--title d-flex align-items-center">
                        <div className="btn--back" onClick={handleOnClickBackButton}>
                            <img className="img-fluid" src={appConfig.scriptDomainUrl+backIconSrc} />
                        </div>
                        <h2 className="header--h2 header--basket -uppercase">Twój koszyk </h2>
                    </div>
                    <CloseButton/>
                </div>
            </div>
            {/* <div className="modalcontent modal--content" data-scrollbar> */}
                {CartList()}
            {/* </div> */}
        </div>
    )
};

export default CartContent;