import {createElement} from "../createElement";

const Toast = (title) =>{

    return(
        <div className="toast--component toastComponent" id="toastComponent">
            <div className="text">
                <div className="dot-flashing"></div>
                <span className="title">{title}</span>
            </div>
        </div>
    )
}

export default Toast;