import {createElement} from "../createElement";

const InpostMap = () => {
    let inpostGeowidgetScript = document.getElementById('inpostGeowidgetScript');
    let inpostGeowidgetStyles = document.getElementById('inpostGeowidgetStyles');


    window.easyPackAsyncInit = function () {
        easyPack.init({});
        var map = easyPack.mapWidget('deliveryInpostMap', function(point){
            const address = 'Paczkomat ' + point.name + ' ' + point.address.line1 + ' ' + point.address.line2;
            appGlobals.selectedInpostData = point;
            document.getElementById('inpostSelectedPickString').innerHTML = address;
            document.querySelector('.inpostDataFields .input--text--component').classList.remove('-error');
            document.getElementById('inpostSelectedPickInput').value = address;
            document.querySelector('[data-autocomplete-name="inpostName"]').innerHTML = point.name;
            document.querySelector('[data-autocomplete-name="inpostAddress1"]').innerHTML = point.address.line1;
            document.querySelector('[data-autocomplete-name="inpostAddress2"]').innerHTML = point.address.line2;
        });
    };

    return(
        <div id="deliveryInpostMapContainer">
            <h4 className="header--h4 -uppercase -margined -buyerdata">Dane dostawy - Paczkomat </h4>

            <div className="inpostDataFields">
                <div className="chosen--inpost--point">
                    <div className="input--text--component">
                        <input type="text"
                           disabled={true}
                           id="inpostSelectedPickInput"
                           name="cart_user_inpost_pick"
                           className="input formValidationItem"
                           data-required="required"
                        />
                    </div>
                    Wybrany paczkomat:
                    <span className="point" id="inpostSelectedPickString"></span>
                    <span className="error">ABY PRZEJŚĆ DO PŁATNOŚCI, PROSZĘ WYBRAĆ PACZKOMAT</span>
                </div>
            </div>

            <div id="deliveryInpostMap" style="height:550px">
                {!inpostGeowidgetScript ? (
                    <script async src="//geowidget.easypack24.net/js/sdk-for-javascript.js" id="inpostGeowidgetScript"></script>
                ):''}
                {!inpostGeowidgetStyles ? (
                    <link rel="stylesheet" href="//geowidget.easypack24.net/css/easypack.css" id="inpostGeowidgetStyles"/>
                ):''}
            </div>
        </div>
    )
}

export default InpostMap;