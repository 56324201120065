import {createElement} from "../createElement";
import errorIconSrc from '../assets/i/error-icon.png';
import infoIconSrc from '../assets/i/info-icon.png';
import warningIconSrc from '../assets/i/warning-icon.png';
import appConfig from "../config";
import MerchantItemSummary from "../Cart/Merchants/Summary/MerchantItemSummary";
import CartProductItem from "../Cart/CartProductItem";
import UnAvailableProduct from "../Checkout/UnAvailableProduct";
import {tools} from "../tools";
import CartContent from "../Cart/CartContent";


function WarningBox(type, text, carts) {
    let image = infoIconSrc;
    if (type == 'WARNING') {
        image = warningIconSrc;
    } else if (type == 'INFO') {
        image = infoIconSrc;
    } else if (type == 'ERROR') {
        image = errorIconSrc;
    } else if (type == 'UNAVAILABLE') {
        image = infoIconSrc;
    }


    const handleCloseWarningPopup = (e) => {
        let popup = document.getElementById('iineWarrningPopupBox');
        popup.remove();
    }

    const handleBackToCheckout = (e) => {
        e.preventDefault();
        let popup = document.getElementById('iineWarrningPopupBox');
        let step1 = document.getElementById('preOrderStep-1');
        let step2 = document.getElementById('preOrderStep-2');
        popup.remove();

        const cartLayer = CartContent();
        tools.setPopupLayer('cartLayer', cartLayer);
        if(step1 && step2){
            step1.style.display = 'block';
            step2.style.display = 'none';
        }
    }

    const handleClose = (e) => {
        if (carts) {
            carts.map((merchant) => {
                merchant.items.map((product) => {
                    tools.setProductQuantity(product, product.unavailableQuantity)
                })
            })

            handleBackToCheckout(e);
        } else {
            handleCloseWarningPopup();
        }
    }

    return (
        <div id="iineWarrningPopupBox"
             className="iine--warrning--box--component d-flex justify-content-center align-items-center">
            <div className={`iine--warrning--box ${carts ? '-unavailable' : ''}`}>
                <div className="_text-center">
                    <img src={appConfig.scriptDomainUrl + image}/>
                    <h3 className="header--h4 warning-title">{text}</h3>
                </div>
                {carts && (
                    <div className="d-flex flex-column mb-3 justify-content-center">
                        {carts.map((merchant) => {
                            return (
                                merchant.items.map((product) => (
                                    <UnAvailableProduct product={product}/>
                                ))
                            )
                        })}
                    </div>
                )}
                <div className="d-flex mt-1 justify-content-center">
                    <button className="btn--light -md mt-2" onClick={handleClose}>{carts ? 'Kontynuuj' : 'Zamknij'}</button>
                </div>
            </div>
        </div>
    )
}

export default WarningBox;