import {createElement} from "../createElement";
import ProductItem from "../Product/ProductItem";
import WidgetProductItem from "./WidgetProductItem";
import arrowSrc from '../assets/i/arrow-carousel.png';
import appConfig from "../config";
import Scrollbar from 'smooth-scrollbar';
import {tools} from "../tools";
import marketPlaceAnalytics from "../analytics";
import appGlobals from "../globals";


function WidgetCarousel(products, index, config, parent) {
    let rows = config.rows;
    let columns = config.columns;
    let widgetHeight = config.height;
    let widgetWidth = config.width;
    // widgetHeight= 500;

    let descriptionHeight = 85;
    let cellHeight = widgetHeight / rows;
    let imageHeight = cellHeight - descriptionHeight;
    let cellWidth = widgetWidth / columns;


    let itemConfig = {
        cellHeight,
        cellWidth,
        imageHeight
    };

    // config.width = 350;


    const getProductsForRow = row => {
        let startFrom = (row - 1) * columns;
        let endTo = row * columns;

        let rowProducts = products.map((product, index) => {
            if (index >= startFrom && index < endTo) {
                return product;
            }
        });
        return rowProducts.filter((el) => {
            return el != undefined
        })
    };

    let animateScrollCarousel = (event, side) => {
        let parent = event.target.closest('.iineWidgetCarousel');
        let carousel = parent.querySelector('.carousel--wrapper');
        const scrollbar = Scrollbar.get(carousel);

        let positionX = scrollbar.scrollLeft;


        if(side==='left'){
            positionX -= 120;
        }
        if(side==='right'){
            positionX += 120;
        }

        scrollbar.scrollTo(positionX,0,300);

        /**
         * Call analytics events / Quantity change
         */
        marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetSwiped,  {direction : side}, products);
    }

    let iineWidgetCarouselAddListener = () => {
        let parentItem = parent ? parent : document;

        // document.querySelectorAll('.iineWidgetCarousel').forEach((element) => {
        parentItem.querySelectorAll('.iineWidgetCarousel').forEach((element) => {
            let leftArrow = element.querySelector('.buttonCarouselLeft');
            let rightArrow = element.querySelector('.buttonCarouselRight');
            let carousel = element.querySelector('.carousel--wrapper');
            const scrollbar = Scrollbar.get(carousel);


            let isVisible = scrollbar && scrollbar.limit && scrollbar.limit.x > 0;
            if(!isVisible){
                element.classList.add('hide-buttons')
            }else{
                element.classList.remove('hide-buttons')
            }

            leftArrow.style.display = 'none';


            if(scrollbar){
                scrollbar.addListener(() => {
                    let isVisible = scrollbar && scrollbar.limit && scrollbar.limit.x > 0;
                    if(!isVisible){
                        element.classList.add('hide-buttons')
                    }else{
                        element.classList.remove('hide-buttons')
                    }

                    let maximumWidth = scrollbar.limit.x;
                    let currentLeft = scrollbar.scrollLeft;

                    if (currentLeft == 0) {
                        leftArrow.style.display = 'none';
                    } else if(isVisible){
                        leftArrow.style.display = 'flex';
                    }

                    if (currentLeft === maximumWidth) {
                        rightArrow.style.display = 'none';
                    } else if(isVisible) {
                        rightArrow.style.display = 'flex';
                    }
                });
            }

        })
    }

    let handleHoverListenerAnalytics = () => {
        //marketPlaceAnalytics.sendAnalyticsData(marketPlaceAnalytics.eventTypes.WidgetHover,  {widgetId : appGlobals.widgets.widgets[index].element}, products);
    }



    setTimeout(() => {
        iineWidgetCarouselAddListener();
        tools.clamp()
    },300);


    return (
        <div style={'margin:0 auto;overflow:auto;max-width:' + config.width + 'px'}
             className="iine--widget--carousel iineWidgetCarousel">

            <div className="btn--carousel -prev buttonCarouselLeft" onClick={(event) => {animateScrollCarousel(event,'left')}}>
                <img src={appConfig.scriptDomainUrl + arrowSrc}/>
            </div>
            <div className="btn--carousel -next buttonCarouselRight" onClick={(event) => {animateScrollCarousel(event,'right') }}>
                <img src={appConfig.scriptDomainUrl + arrowSrc}/>
            </div>

            <div id="carousel--wrapper" className="carousel--wrapper" data-scrollbar /*onMouseEnter={handleHoverListenerAnalytics}*/>
                {[...Array(rows)].map((row, i) => {
                    let rowProducts = getProductsForRow(i + 1);
                    return (
                        <div className="widget--carousel--row item" style={'width:' + cellWidth * columns + 'px'}>
                            {rowProducts.map(product => (
                                <WidgetProductItem product={product} widgetId={index} itemConfig={itemConfig}/>
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>

    );
}

export default WidgetCarousel;