import Fingerprint2 from 'fingerprintjs2';
import { UserData } from './userData';

export class IineFingerprint {
    constructor() {
        this.userData = new UserData();
    }

    getFingerprintData() {
        let userDataLocalStorage = JSON.parse(localStorage.getItem("iineUserData"));
        if(!userDataLocalStorage || userDataLocalStorage.length == 0) {
            this._createFingerprint();
        }
        else {
            this.userData = userDataLocalStorage;
        }

        return this.userData;
    }
    
    _createFingerprint() {
        let self = this;
    
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {
                self._setFingerprint();
            })
        } else {
            setTimeout(function () {
                self._setFingerprint(); 
            }, 500)
        }
    }
    
    _setFingerprint() {
        let self = this;
        var options = {
            excludes: {
                audio: true,
                deviceMemory: true,
                enumerateDevices: true
            }
        };
    
        Fingerprint2.get(options, function (components) {
            var values = components.map(function (component) { return component.value });
            var murmur = Fingerprint2.x64hash128(values.join(''), 29);
    
            self.userData.fingerprint = murmur;
            localStorage.setItem("iineUserData", JSON.stringify(self.userData));
        });
    }
}