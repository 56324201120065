import {createElement} from "../createElement";
import {helpers, tools} from "../tools";
import CartContent from "./CartContent";
import appConfig from "../config";
import noImageSrc from "../assets/i/no-image.png";

const handleOnClickHeaderCart = () => {
    const cartLayer = CartContent();
    tools.setPopupLayer('cartLayer', cartLayer);
}

const HeaderCartLastProductBox = (product) => {
    return (
        <div id="cartLastProductBox" className="header--cart--last--product--box">
            <div className="d-flex">
                <div className="cart--last--product--box--image">
                    <img
                        src={product.imageUrl ? product.imageUrl : (product.images && typeof product.images[0].thumbnail !== 'undefined') ? product.images[0].thumbnail : appConfig.scriptDomainUrl+noImageSrc}/>
                </div>
                <div className="cart--last--product--box--details">
                    <h3 className="brand">{product.brand}</h3>
                    <div className="description">{product.name}</div>
                    <div className={`price ${product.oldPrice ? '-highlighted' : ''}`}>
                        {helpers.setFormatNumberString(product.price, product.currency)} {product.oldPrice ? <span
                        className="old">{helpers.setFormatNumberString(product.oldPrice, product.currency)} </span> : ''}
                    </div>
                </div>
            </div>
            <div className="cart--last--product--box--button">
                <button onClick={handleOnClickHeaderCart} className=" btn--dark -md _w-100 text-uppercase">Przejdź do koszyka</button>
            </div>
        </div>
    );
};

export default HeaderCartLastProductBox;