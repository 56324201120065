import {createElement} from "../createElement";
import {helpers} from "../tools";
import appConfig from "../config";
import noImageSrc from "../assets/i/no-image.png";

const UnAvailableProduct = (props) => {
    const {product} = props;

    return (
        <div className="cart--product--item">
            <div className="product--image">
                <img src={product.imageUrl || appConfig.scriptDomainUrl+noImageSrc} onError={(e) => e.target.src = appConfig.scriptDomainUrl+noImageSrc} alt="" className="img"/>
            </div>

            <div className="details">
                <div className="cart--product--info pt-1">
                    <h3 className="brand--name">{product.brand}</h3>
                    <div className="product--description">{product.name}</div>
                </div>
                <div className="cart--product--details">
                    <div className="cart--label d-none d-md-block">Szczegóły</div>
                    {product.color ? (
                        <div className="cart--detail--info">
                            Kolor: <strong>{product.color}</strong>
                        </div>
                    ) : ''}
                    {product.size ? (
                        <div className="cart--detail--info mb-md-0">
                            Rozmiar: <strong>{product.size}</strong>
                        </div>
                    ) : ''}
                </div>
                <div className="cart--product--quantity">
                    <div className="cart--detail--info">
                        Ilość: <strong>{product.unavailableQuantity}</strong>
                    </div>
                </div>
                <div className="cart--product--summary">
                    <div className="cart--label mb-1 d-none d-md-block">Suma</div>
                    <div className={`product--price`}>
                        {helpers.setFormatNumberString(product.price * product.unavailableQuantity)}
                    </div>
                </div>
                <div className="cart--product--info">

                </div>
            </div>

        </div>
    )
}

export default UnAvailableProduct;