// function createElement(tagName, attrs = {}, ...children) {
//     const elem = Object.assign(document.createElement(tagName), attrs)
//     for (const child of children) {
//         if (Array.isArray(child)) elem.append(...child)
//         else elem.append(child)
//     }
//     return elem
// }
// export default createElement;

export const createElement = (tag, props, ...children) => {
    if (typeof tag === "function") return tag(props, children)
    const element = document.createElement(tag)


    Object.entries(props || {}).forEach(([name, value]) => {
        if(name =='className'){
            name = 'class';
        }
        if (name.startsWith('on') && name.toLowerCase() in window)
            element.addEventListener(name.toLowerCase().substr(2), value)
        else element.setAttribute(name, value.toString())
    })

    children.forEach((child) => {
        appendChild(element, child)
    })

    return element
}


const appendChild = (parent, child) => {
    if (Array.isArray(child))
        child.forEach((nestedChild) => appendChild(parent, nestedChild))
    else
        parent.appendChild(
            child && child.nodeType ? child : document.createTextNode(child)
        )
}

const createFragment = (props, ...children) => {
    return children
}
