import {createElement} from "../../../createElement";
import {helpers, tools} from "../../../tools";
import appConfig from "../../../config";
import CartProductItem from "../../CartProductItem";
import basketIconSrc from '../../../assets/i/basket-icon.png';


const MerchantItemSummary = (merchantItem) => {
    const {merchant} = merchantItem;

    const delivery = tools.getMerchantSelectedShippingMethod(merchant.merchantId)

    return (
        <div className="merchant--item -summary">
            <h5 className="name">
                <img src={appConfig.scriptDomainUrl + basketIconSrc} alt="" className="img" />
                Zakupy w {merchant.merchantName}
            </h5>
            
            {merchant.items.map((product) => (
                <CartProductItem product={product} isSumupOrder={true}/>
            ))}
            
            <div className="merchant--summary--payment">
                <div className="checkout--separator"></div>
                <div className="row">
                    <div className="col">
                        <div>Sposób dostawy: <strong className="_text-uppercase">{delivery.name}</strong></div>
                    </div>
                    <div className="col-auto">{helpers.setFormatNumberString(delivery.cost)}</div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="header">Razem: <span className="light">(w tym VAT)</span></div>
                    </div>
                    <div className="col-auto">{helpers.setFormatNumberString(tools.getMerchantItemsPrice(merchant.merchantId, true))}</div>
                </div>                
            </div>
        </div>
    )
};

export default MerchantItemSummary;