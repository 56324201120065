import {createElement} from "../createElement";
import infoIconSrc from '../assets/i/icon-info.png';
import appConfig from "../config";
import minusIconSrc from "../assets/i/icon-minus.png";


const CheckoutInputInfo = (tip) => {
    return (
        <div className="input--info--component">
            <div className="icon">
                <img src={appConfig.scriptDomainUrl+infoIconSrc} alt="" className="img-fluid" />
            </div>
            <div className="tooltip">
                {/*<div className="title">NAZWISKO</div>*/}
                <div>
                  {tip}
                </div>
            </div>
        </div>
    )
}

export default CheckoutInputInfo;
