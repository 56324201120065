import {createElement} from "../createElement";
import CheckoutForm from "./CheckoutForm";


const CheckoutContent = () => {
    return (
        <div id="checkoutLayer" className="single--layer-app checkoutLayer">
            <div className="modalcontent modal--content">
                {CheckoutForm()}
            </div>
        </div>
    )
};

export default CheckoutContent;