import { createElement } from "../createElement";
import { customModal, tools } from "../tools";

//TODO: Styles of buttons

/**
 * CookieConsent Component
 * @returns DOM Object of Cookie Consent Component
 */
const CookieConsent = () => {
 
    /**
     * SetCookie
     * Seting cookie localStorage and display/hide Layers
     * @param {boolean} status status of clicked cookie Agreement
     */
    function setCookie(status) {
        
        let cookieComponent = document.querySelectorAll('.cookie--component');
        /**
         * Remove element from DOM with fadeOut 
         * @param {array} el array of faded element
         * @param {*} speed speed of Fadeout
         */
        const removeFadeOut = (el, speed) => {
            var seconds = speed / 1000;
            el.style.transition = "opacity " + seconds + "s ease";
            el.style.opacity = 0;

            setTimeout(function () {
                el.parentNode.removeChild(el);
            }, speed);
        }
        /**
         * Remove All Cookies components from page
         * @param {array} cookieComponent array of all cookieComponents on page
         */
        const removeAllCookieComponents = (cookieComponent) => {
            cookieComponent.forEach(component => {
                removeFadeOut(component, 500);
            });
        };

        //Set localStorage cookie status
        tools.setCookieConsent(status);

        /**
         * Check clicked cookie state set properly layer view
         */
        if (status) {
            removeAllCookieComponents(cookieComponent);
        } else {
            customModal.hide();

        }

    }
    /**
     * Element return
     */
    return (
        <div className="cookie--component">
            <div className="cookie--bar">
                <div className="info">
                    iiTako Marketplace używa plików cookies,
                    aby umożliwić Ci składanie zamówień oraz dostosować swoje działanie
                    do Twoich potrzeb. Klikając "ZGADZAM SIĘ" wyrażasz zgodę na używanie przez nas plików cookies.
                    <br className="d-md-none" />
                    <br />  Przeczytaj więcej o naszej <a href="https://docs.iine.eu/legal/iiTako_marketplace_Privacy_Policy.pdf" target="_blank"> polityce prywatności</a>.<br />
                </div>
                <div class="close">
                    <button className="btn--light" onClick={() => { setCookie(false) }}>Zakończ zakupy</button>
                </div>
                <div class="agree">
                    <button className="btn--dark" id="cookieAgree" onClick={() => { setCookie(true) }} >Zgadzam się</button>
                </div>
            </div>
        </div>
    )
}

export default CookieConsent;