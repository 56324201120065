import {createElement} from "../createElement";
import {helpers, tools} from "../tools";
import CartProductItem from "./CartProductItem";
import CheckoutContent from "../Checkout/CheckoutContent";
import appGlobals from "../globals";
import przelewy24Src from '../assets/i/przelewy24-logo.png';
import appConfig from "../config";
import CheckoutStepSumup from "../Checkout/Steps/CheckoutStepSumup";
import CheckoutSumupBox from "../Checkout/CheckoutSumupBox";
import basketIconSrc from "../assets/i/icon-basket-big.png";
import api from "../api";
import MerchantList from "./Merchants/MerchantList";


const handleOnClickGoToCheckoutPage = () => {
    let cartListProducts = tools.getCart();
    if (!cartListProducts.length) {
        return false;
    }

    const isShippingMethodSelected = tools.isShippingMethodSelected();
    if (!isShippingMethodSelected) {
        return false;
    }

    const checkoutContent = CheckoutContent();
    let checkoutForm = document.getElementById('checkoutForm');
    if (checkoutForm) {
        let checkoutStepFormContainer = document.getElementById('checkoutStepFormContainer');
        // let checkoutSumumContnet = document.getElementById('checkoutSummUpContainer');

        // if(checkoutSumumContnet){
        // checkoutSumumContnet.replaceWith(CheckoutStepSumup());
        // }
        if (checkoutStepFormContainer) {
            let SumUpBox = CheckoutSumupBox(1);
            checkoutStepFormContainer.querySelectorAll('.checkoutSummaryBox').forEach(element => {
                element.replaceWith(SumUpBox);
            })
        }

        tools.updateSumUpBoxCartPrice();
        tools.setActivePopupLayer('checkoutLayer');
        tools.scrollToTop();
    } else {
        tools.setPopupLayer('checkoutLayer', checkoutContent);
        tools.updateSumUpBoxCartPrice();
        tools.scrollToTop();
    }

    // refresh produktow wyrednerowanych w merchant list na podsumowaniu
    tools.renderSummaryMerchantListCheckout();
};


const renderMerchantProducts = async (refreshShipping = false) => {
    let merchantsContainer = document.getElementById('merchantsList');
    if(merchantsContainer && !refreshShipping){
        merchantsContainer.replaceWith(MerchantList(refreshShipping));
    }
}

const CartList = (refreshShipping = false, isRefresh = false ) => {

    let cartListProducts = tools.getCart();

  if(!refreshShipping || (refreshShipping && isRefresh)){
        api.prepareCart(tools.getCart()).then((res) => {
            tools.setPreparedCart(res.carts);
            tools.updateSumUpBoxCartPrice();
            setTimeout(() => {
                renderMerchantProducts();
                tools.refreshScrollbarElement('cartProductsContainerScrollbar');
            }, 100)

        })
    }else {
        tools.updateSumUpBoxCartPrice();
        renderMerchantProducts(refreshShipping);
        tools.refreshScrollbarElement('cartProductsContainerScrollbar');
    }




    let totalPrice = tools.getPreparedCartTotalPrice(true);
    let defaultCurrency = tools.getDefaultProductsCurrency();
    return (
        <div id="cartList">
            {cartListProducts.length ? (
                <div className={`cart--container ${cartListProducts.length === 1 ? '-one' : ''}`}>
                    <div className="products" id="cartProductsContainerScrollbar" data-scrollbar>
                        <MerchantList/>
                        {/*<div id="merchantsList"></div>*/}
                        {/*{cartListProducts.map((product) => (*/}
                        {/*    <CartProductItem product={product}/>*/}
                        {/*))}*/}
                    </div>
                    <p className="info">* Dodanie produktów do koszyka nie oznacza ich rezerwacji​</p>
                    <div className="summary">
                        <h3 className="cart--summary--title">Podsumowanie zamówienia</h3>
                        <div className="cart--summary--info">Łącznie <span
                            className="autocompleteCartPriceText">{helpers.setFormatNumberString(totalPrice, defaultCurrency)}</span>
                        </div>
                        <div className="cart--summary--info">Dostawa <span
                            className="autocompleteDeliveryPriceText">{helpers.setFormatNumberString(0, 'PLN')}</span></div>
                        <div className="cart--summary--info -bigger">
                            <div><b>Do zapłaty</b> <div className="vat">(w tym VAT)</div></div>
                            <strong
                                className="autocompleteTotalPriceText">{helpers.setFormatNumberString(totalPrice, defaultCurrency)}</strong>
                        </div>
                        <button onClick={handleOnClickGoToCheckoutPage} id="triggerGoToOrder"
                                className="btn--dark -md _w-100 text-uppercase">Przejdź do zamówienia
                        </button>
                        <h3 className="summary--accept text-center">Płatności obsługuje</h3>
                        <div className="summary--payments--container">
                            <div className="item">
                                <img src={appConfig.scriptDomainUrl + przelewy24Src} alt="" className="img-fluid"/>
                            </div>
                            {/*{appGlobals.paymentMethods.map(paymentMethod => {*/}
                            {/*    return (*/}
                            {/*        <div className="item">*/}
                            {/*            <img src={paymentMethod.imageUrl} alt="" className="img-fluid" />*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*})}*/}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-center empty--cart">
                    <div className="empty--content">
                        <img className="icon--basket" src={appConfig.scriptDomainUrl + basketIconSrc}/>
                        <h2 className="title">Dodaj produkty do swojego koszyka <br /> aby finalizować swoje zamówienia</h2>
                    </div>
                </div>
            )}


        </div>
    )
}


export default CartList;
