import appConfig from "./config";

// export const apiHost = axios.create({
//     baseURL: appConfig.apiUrl
// });

const apiUrl = appConfig.apiUrl;
const apiProductsUrl = appConfig.apiUrl + 'websites/';
// const fingerPrint = 2;

const apiFetch = async (endpoint, params = {}) => {
    // const url = apiUrl + endpoint;
    let response = await fetch(endpoint, params);
    if(response.status === 404){
        return null;
    }
    return response.json()
};

const api = {
    getProductsList: async (websiteId) => {
        const baseEndPoint = apiProductsUrl + websiteId +'/products';
        return apiFetch(baseEndPoint);
    },

    getProduct: async (productId) => {
        const baseEndPoint = apiUrl + '/products/' + productId;
        return apiFetch(baseEndPoint);
    },

    getCheckoutData: async () => {
        const baseEndPoint = apiUrl + 'orders/checkoutdata';
        return apiFetch(baseEndPoint);
    },

    getSummary: async (orderId, token) => {
        const baseEndpoint = apiUrl + 'Orders/' + orderId+ '?token='+token;
        return apiFetch(baseEndpoint);
    },

    sendOrder: async (data) => {
        let response = await fetch(apiUrl + 'orders', {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        });

        return await response.json();
    },
    refreshShippingMethods: async (data) => {
        let response = await fetch(apiUrl + 'orders/shippingdata', {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        });

        return await response.json();
    },

    prepareCart: async (userCart) => {
        const baseEndPoint = apiUrl + 'orders/basketData';
        let cartClone = [...userCart];
        cartClone.map(item => item.price = parseFloat((item.price * item.selectedQuantity).toFixed(2)));
        let response = await fetch(baseEndPoint,{
            method: 'post',
            body: JSON.stringify({items:cartClone, websiteId: appConfig.websiteId}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'mode': 'cors'
            },
        })

        return await response.json()
    },
};

export default api;
