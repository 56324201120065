import {createElement} from "../createElement";
import ProductList from "./ProductList";
import {tools} from "../tools";
import appGlobals from "../globals";
import ProductDetailsName from "./ProductDetails/ProductDetailsName";
import ProductDetailsDescription from "./ProductDetails/ProductDetailsDescription";
import ProductDetailCarousel from "./ProductDetails/ProductDetailCarousel";
import marketPlaceAnalytics from "../analytics";


function handleAddProductToCart(product) {
    // const selectedVariant = tools.findSelectedVariantProduct(product);
    // product = tools.findSelectedVariantProduct(product);
    let parentProduct = product;
    // let parentProductId = product.parentId ? product.parentId : product.id;
    product = appGlobals.preparedProduct;


    if (validateProductAddCart()) {
        let productVariantSize = product.size ? product.size : 0;
        let productVariantColor = product.color ? product.color : 0;

        product.hash = tools.generateProductHash(product.id, productVariantSize, productVariantColor);
        // product.parentId = parentProductId;

        const cart = tools.getCart();
        let cartProductIndex = tools.getCartProductIndexByHash(product.id);

        if (product.availableQuantity <= 0) {
            return false
        }

        if (cart && cart[cartProductIndex] && typeof cart[cartProductIndex].selectedQuantity !== 'undefined') {
            if (product.availableQuantity < cart[cartProductIndex].selectedQuantity) {
                return false;
            }
        }

        tools.addProductToCart(product);
        tools.refreshProductVariantOptions();
        tools.setDisabledProductVariantOptions(parentProduct);
    }
}

function validateProductAddCart() {
    let validate = true;
    let productSize = document.getElementsByName('product_size')[0];
    let productColor = document.getElementsByName('product_color')[0];

    if (productSize) {
        if (!productSize.value) {
            productSize.parentNode.classList.add('-error')
            validate = false;
        } else {
            productSize.parentNode.classList.remove('-error');
        }
    }

    if (productColor) {
        if (!productColor.value) {
            productColor.parentNode.classList.add('-error');
            validate = false;
        } else {
            productColor.parentNode.classList.remove('-error');
        }
    }


    return validate;
}


function getProductVariant(product, variant) {
    let variants = [];
    if(!product.variants){
        return [];
    }
    product.variants.map(productVariant => {
        variants.push(productVariant[variant])
    });
    return variants.filter((item, index) => {
        return variants.indexOf(item) === index && item != null;
    })
}


function refreshProductVariants(product, selectedVariantType, selectedVariantValue, secondVariant) {

    const getVariants = () => {
        let variants = [];
        if(!product.variants){
            return variants;
        }

        product.variants.map(productVariant => {
            let cartProductIndex = tools.getCartProductIndexByHash(productVariant.id);
            const cart = tools.getCart();
            let cartQuantity = cartProductIndex >= 0 && cart[cartProductIndex].selectedQuantity ? cart[cartProductIndex].selectedQuantity : 0;

            if (productVariant[selectedVariantType] === selectedVariantValue && (productVariant.availableQuantity - cartQuantity > 0)) {
                variants.push(productVariant[secondVariant]);
            }
        });

        return variants;
    }

    appGlobals.currentSelectedVariants[secondVariant] = getVariants();

    if(selectedVariantType == 'color'){
        appGlobals.loadedProductImages = false;
    }

    // const disableVariantOptions = (currentSelect, targetSelect) => {
    //     let element = document.getElementsByName(currentSelect)[0];
    //     if (!element) {
    //         return false;
    //     }
    //     //
    //     let onChangeInput = () => {
    //         const select = document.getElementsByName(targetSelect)[0];
    //         console.log(select);
    //         if (select) {
    //             console.log(select.value);
    //             select.querySelector('option').value = 'M';
    //         }
    //
    //     //     let variants = getVariants();
    //     //
    //     //     const options = Array.from(select.parentNode.nextSibling.querySelectorAll('.choices__item--choice'));
    //     //     options.map(option => {
    //     //         option.classList.remove('choices__item--disabled');
    //     //         if (!variants.includes(option.getAttribute('data-value'))) {
    //     //             option.classList.add('choices__item--disabled');
    //     //         }
    //     //     })
    //     }
    //
    //     element.addEventListener('change', onChangeInput, {once: true})
    // };

    if(selectedVariantType && selectedVariantValue){
        let targetType = document.getElementsByName(`product_${selectedVariantType}`)[0];
        if(targetType.length){
            targetType.parentNode.classList.remove('-error');
        }
    }


    // update select values
    // color
    // disableVariantOptions('product_color', 'product_size');
    // size
    // disableVariantOptions('product_size', 'product_color');

    // update product details
    // product.parentId = product.id;
    // product.availableQuantity = product.quantity; // todo adrian temp
    appGlobals.preparedProduct = product;

    let preparedProductVariant = tools.findSelectedVariantProduct(product);
    if(!preparedProductVariant){
        preparedProductVariant = product.variants[0];
    }

    if (preparedProductVariant) {
        Object.entries(preparedProductVariant).map(obj => {
            appGlobals.preparedProduct[obj[0]] = obj[1];
        });
    }

    if(appGlobals.preparedProduct.price == 0){
        appGlobals.preparedProduct.price = appGlobals.selectedProductPrice;
    }

    updateProductDetails();
    tools.setDisabledProductVariantOptions(product);
}

const updateProductDetails = () => {
    if (!appGlobals.preparedProduct) {
        return false;
    }
    const ProductDetailsNameContent = ProductDetailsName(appGlobals.preparedProduct);
    const ProductDetailsDescriptionContent = ProductDetailsDescription(appGlobals.preparedProduct);
    const ProductDetailsCarousel = ProductDetailCarousel(appGlobals.preparedProduct);
    const ProductBuyButton = renderButton(appGlobals.preparedProduct);
    if (ProductDetailsNameContent) {
        document.getElementById('productDetailsName').replaceWith(ProductDetailsNameContent);
    }
    if (ProductDetailsDescriptionContent) {
        document.getElementById('productDetailsDescription').replaceWith(ProductDetailsDescriptionContent);
        setTimeout(() => {
            tools.initScrollBarsElement(  document.getElementById('productDetailsDescription'));
        },200)
    }
    if (ProductBuyButton) {
        document.getElementById('productBuyButton').replaceWith(ProductBuyButton);
    }

    if(!appGlobals.loadedProductImages){
        if (ProductDetailsCarousel && appGlobals.preparedProduct.images) {
            document.getElementById('productCarouselContainer').replaceWith(ProductDetailsCarousel);
            setTimeout(() => {
                tools.createProductDetailCarousels();
            },200)
        }
        appGlobals.loadedProductImages = true;
    }

};


function renderButton(product) {
    // if (!product.availableQuantity) {
    //     return (
    //         <div id="productBuyButton">
    //             <button className="btn--dark mt-md-2 -buy" disabled={true}>
    //                 Dodaj do koszyka
    //             </button>
    //         </div>
    //     )
    // }
    return (
        <div id="productBuyButton">
            <button className="btn--dark mt-md-2 text-uppercase -buy" onClick={() => {
                handleAddProductToCart(product)
            }}>
                Dodaj do koszyka
            </button>
        </div>
    )
}

function ProductDetail(props) {
    const {product} = props;
    product.parentId = product.id;
    marketPlaceAnalytics.currentProductsList = [product];

    setTimeout(()=>{
        product.parentId = product.id;
        // if(tools.getSelectedProductDetail('product_color') || tools.getSelectedProductDetail('product_size')){
        appGlobals.currentSelectedVariants = {};
        refreshProductVariants(product);
        tools.setDisabledProductVariantOptions(product);
        // }
    },200);


    function renderSelectOptions(option, secondOption, placeholder) {
        let options = getProductVariant(product, option);
        if (!options.length || options == undefined) {
            return '';
        }

        return (
            <div>
                <select name={'product_' + option}
                        className="customSelect eventSelectInput"
                        onChange={() => {
                            refreshProductVariants(product, option, event.target.value, secondOption)
                        }}
                >
                    {options.length > 1 ? (
                        <option disabled={true} selected={true} value="">{placeholder}</option>
                    ) : ''}

                    {options.map(option => {
                        return (
                            <option value={option}><span> {option} </span></option>
                        )
                    })}
                </select>
            </div>
        )
    }

    return (
        <div className="product--detail">
            <div className="row">
                <div className="col-md-6">
                    {ProductDetailCarousel(product)}
                </div>
                <div className="col-md-6">
                    <div className="details--box--container">
                        <div className="details--box">
                            {ProductDetailsName(product)}

                            {renderSelectOptions('color','size', 'Wybierz kolor')}
                            {renderSelectOptions('size', 'color','Wybierz rozmiar')}


                            {renderButton(product)}

                        </div>


                        {ProductDetailsDescription(product)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;
