import {createElement} from "../createElement";
import HeaderCart from "../Cart/HeaderCart";
import ProductList from "./ProductList";
import CloseButton from "../Common/CloseButton";
import appGlobals from "../globals";
import marketPlaceAnalytics from "../analytics";

const ProductListContent = () => {
    const activeIndex = appGlobals.activeWidgetIndex ? appGlobals.activeWidgetIndex : 0;
    let productList;
    if (appGlobals.blockApiCalls) {
        productList = appGlobals.previewCarouselProducts.products;
    } else {
        productList = appGlobals.widgets.widgets[activeIndex].products
    }
    marketPlaceAnalytics.currentProductsList = productList;

    return (
        <div id="productListLayer" className="single--layer-app productListContainer">
            <div className="app--header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="app--header--title">
                        <h2 className="header--h2" id="productListHeader">Miejsce na tekst - opcjonalnie </h2>
                    </div>
                    <div className="d-flex">
                        <div className="app--header--cart">
                            {HeaderCart}
                        </div>
                        <CloseButton/>
                    </div>
                </div>
            </div>
            <div className="modalcontent modal--content productListContainerScroll" data-scrollbar>
                <ProductList products={productList}/>
            </div>
        </div>
    )
}


export default ProductListContent;