import {createElement} from "../createElement";

const Preloader = (title) =>{

    return(
        <div className="preloader--component preloaderComponent" id="iinePreLoader">
            <div className="text">
                <div className="dot-flashing"></div>
                {/* <span className="title">{title}</span> */}
            </div>
        </div>
    )
}

export default Preloader;