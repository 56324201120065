import {createElement} from "../../createElement";
import CheckoutSumupBox from "../CheckoutSumupBox";
import InpostMap from "../InpostMap";
import CheckoutInputInfo from '../CheckoutInputInfo';
import {helpers, tools} from "../../tools";
import marketPlaceAnalytics from "../../analytics";
import Scrollbar from "smooth-scrollbar";
import CheckoutProductsList from "./CheckoutProductsList";


const getInpostMap = () => {
    return InpostMap();
};

const handleAddEventListenerOnChangeInput = () => {
    document.querySelectorAll('.formValidationItem').forEach(input => {
        input.addEventListener('change', onChangeInputValue);
    })
}

const onChangeInputValue = (event) => {
    helpers.validateInputField(event.currentTarget);
}

const handleOnChangeIsInvoice = (event) => {
    const isInvoice = event.target.checked;
    const invoiceData = document.getElementById('invoiceData');
    const autocompleteInvoiceDetails = document.getElementById('autocompleteInvoiceDetails');
    let autocompleteCompanyInvoice = document.querySelectorAll('.autocompleteCompanyInvoice');
    let autocompletePersonalInvoice = document.querySelectorAll('.autocompletePersonalInvoice');

    if (isInvoice) {
        invoiceData.style.display = 'block';
        autocompleteInvoiceDetails.style.display = 'flex';
        autocompletePersonalInvoice.forEach(el => {
            el.style.display = 'none';
        })
        autocompleteCompanyInvoice.forEach(el => {
            el.style.display = 'flex';
        })

    } else {
        invoiceData.style.display = 'none';
        autocompleteInvoiceDetails.style.display = 'none';

        autocompleteCompanyInvoice.forEach(el => {
            el.style.display = 'none';
        })

        autocompletePersonalInvoice.forEach(el => {
            el.style.display = 'flex';
        })
    }
};


const prepareDeliveryMethods = () => {
    const preparedCart = tools.getPreparedCart();
    let isInpostWidgetDelivery = false;
    let isCourierDelivery = false;

    preparedCart.map((merchant) => {
        let merchantDelivery = tools.getMerchantSelectedShippingMethod(merchant.merchantId);
        if (merchantDelivery.showGeowidget) {
            isInpostWidgetDelivery = true;
        } else {
            isCourierDelivery = true;
        }
    });

    setTimeout(() => {
        renderInpostWidget(isInpostWidgetDelivery);
        renderCourierDataContainer(isCourierDelivery);
    }, 100)
}

const renderCourierDataContainer = (showContainer) => {
    const courierDataContainer = document.getElementById('courierDataContainer');
    const autocompleteCourierDataContainer = document.getElementById('autocompleteUserDataDetails');

    if (showContainer) {
        courierDataContainer.style.display = 'block';
        autocompleteCourierDataContainer.style.display = 'block';
        courierDataContainer.querySelectorAll('input').forEach((input) => {
            input.classList.add('formValidationItem');
        })
    } else {
        courierDataContainer.style.display = 'none';
        autocompleteCourierDataContainer.style.display = 'none';
        courierDataContainer.querySelectorAll('input').forEach((input) => {
            input.classList.remove('formValidationItem');
        })
    }
}

const renderInpostWidget = (showWidget) => {
    let deliveryInpostMapContainer = document.getElementById('deliveryInpostMapContainer');
    let inpostSelectedPickInput = document.getElementById('inpostSelectedPickInput');
    let autocompleteUserDataDetails = document.getElementById('autocompleteUserDataDetails');
    let autocompleteInpostDataDetails = document.getElementById('autocompleteInpostDataDetails');

    if (showWidget) {
        if (document.getElementById('deliveryInpostMap') != null) {
            deliveryInpostMapContainer.style.display = 'block';
        } else {
            const map = getInpostMap();
            deliveryInpostMapContainer.replaceWith(map);
        }

        if (autocompleteUserDataDetails && autocompleteInpostDataDetails) {
            autocompleteInpostDataDetails.style.display = 'block';
        }

        if (inpostSelectedPickInput) {
            inpostSelectedPickInput.setAttribute('data-required', 'required');
        }
    } else {
        appGlobals.selectedInpostData = null;
        deliveryInpostMapContainer.style.display = 'none';
        let inpostSelectedPickUp = document.getElementById('inpostSelectedPickString');
        if (inpostSelectedPickUp) {
            inpostSelectedPickUp.innerHTML = '';
        }

        if (autocompleteUserDataDetails && autocompleteInpostDataDetails) {
            // autocompleteUserDataDetails.style.display = 'block';
            autocompleteInpostDataDetails.style.display = 'none';
        }
        if (inpostSelectedPickInput) {
            inpostSelectedPickInput.removeAttribute('data-required');
            inpostSelectedPickInput.value = null;
        }

        const scrollbar = Scrollbar.get(document.getElementById('checkoutContainer'));
        let el = document.getElementById('clientDataSection');
        if (el.length) {
            scrollbar.scrollIntoView(el, {
                alignToTop: false,
                onlyScrollIfNeeded: false,
            });
        }
    }
}
const renderCheckoutProducts = () => {
    let productsContainer = document.getElementById('renderedCheckoutProducts');
    const products = CheckoutProductsList();
    productsContainer.replaceWith(products);
};
const CheckoutStepForm = () => {
    prepareDeliveryMethods();
    setTimeout(() => {
        handleAddEventListenerOnChangeInput();
        renderCheckoutProducts();
    }, 500)

    const handleOnChangeInvoiceType = (type) => {

        let companyInputs = document.querySelector(".companyInvoice");
        let personalInputs = document.querySelector(".personalInvoice");

        let companyNipInput = document.getElementById("companyNipInput");
        let companyNameInput = document.getElementById("companyNameInput");


        let toggle = (element, visible) => {
            element.style.display = visible ? "block" : "none";
        }

        const setInvoice = (type) => {
            let isCompany = type === 'COMPANY';
            toggle(companyInputs, isCompany);
            toggle(personalInputs, !isCompany);

            tools.toggleAutocompletePersonalInvoice(!isCompany);
            if(isCompany){
                companyNipInput.classList.add('formValidationItem');
                companyNameInput.classList.add('formValidationItem');
            }else{
                companyNipInput.classList.remove('formValidationItem');
                companyNameInput.classList.remove('formValidationItem');
                companyNipInput.closest('.input--text--component').classList.remove('-error');
                companyNameInput.closest('.input--text--component').classList.remove('-error');
            }

            // companyNipInput.setAttribute('data-required', isCompany);
            // companyNameInput.setAttribute('data-required', isCompany);
        };

        setInvoice(type);
    }
    const onChangeName = (e) => {
        let names = document.querySelectorAll('.nameDuplicated');
        names.forEach(function (name) {
            name.value = e;
        });
    };

    const onChangeSurName = (e) => {
        let surnames = document.querySelectorAll('.surnameDuplicated');
        surnames.forEach(function (surname) {
            surname.value = e;
        });
    };

    const onChangeFlatValue = (e) => {
        if (e.target.value) {
            document.querySelector('.userAddressFlatNumber').style.display = 'inline';
        } else {
            document.querySelector('.userAddressFlatNumber').style.display = 'none';
        }
    }

    const onChangeInvoiceFlatValue = (e) => {
        if (e.target.value) {
            document.querySelector('.userInvoiceAddressFlatNumber').style.display = 'inline';
        } else {
            document.querySelector('.userInvoiceAddressFlatNumber').style.display = 'none';
        }
    }

    return (
        <div className="checkout--container">
            <div id="checkoutContainer" className="details" data-scrollbar>
                <div className="row">
                    <div className="col-8">
                        <h3 className="header--h3 -uppercase data">Twoje dane i adres dostawy</h3>
                    </div>
                    <div className="col-4">
                        <div className="required--label--title">* pola obowiązkowe</div>
                    </div>
                </div>

                <div className="checkout--separator"></div>

                <h4 className="header--h4 -uppercase -buyerdata">Dane kupującego</h4>
                <div className="row" id="clientDataSection">
                    <div className="col-md-12"></div>
                    <div className="col-md-6">
                        <div className="input--text--component">
                            <label htmlFor="firstname" className="label">Imię
                                <span className="required--component">*</span>
                            </label>
                            <div className="input--wrapper">
                                <input type="text"
                                       name="firstname"
                                       placeholder="Imię"
                                       className="input -capitalize formValidationItem"
                                       data-name="userName"
                                       data-api-field="buyerAddress"
                                       data-api-name="firstName"
                                       data-api-field-group="firstName"
                                       onkeyup={(event) => {
                                           onChangeName(event.target.value)
                                       }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input--text--component">
                            <label htmlFor="cart_user_surname" className="label">Nazwisko<span className="required--component">*</span></label>
                            <div className="input--wrapper">
                                <input type="text"
                                       name="cart_user_surname"
                                       placeholder="Nazwisko"
                                       className="input -capitalize formValidationItem"
                                       data-name="userSurname"
                                       data-api-field="buyerAddress"
                                       data-api-name="lastName"
                                       data-api-field-group="lastName"
                                       onkeyup={(event) => {
                                           onChangeSurName(event.target.value)
                                       }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="input--text--component">
                            <label htmlFor="cart_user_phone" className="label">Telefon komórkowy
                                {/*<span className="required--component">*</span>*/}
                            </label>
                            <div className="input--wrapper">
                                <input type="text"
                                       name="cart_user_phone"
                                       placeholder="Telefon komórkowy"
                                       className="input formValidationItem"
                                       data-name="userPhone"
                                       data-api-name="phone"
                                       data-api-field="buyerAddress"
                                       data-api-field-group="phone"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="input--text--component">
                            <label htmlFor="cart_user_email" className="label">E-mail
                                {/*<span className="required--component">*</span>*/}
                            </label>
                            <div className="input--wrapper">
                                <input type="email"
                                       name="cart_user_email"
                                       placeholder="E-mail"
                                       className="input formValidationItem"

                                       data-name="userEmail"
                                       data-api-name="email"
                                       data-api-field="buyerAddress"
                                       data-api-field-group="email"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-end">
                        <div className="checkbox--wrapper -dark -invoice">
                            <label className="label">
                                <input type="checkbox"
                                       id="isInvoiceInput"
                                       name="cart_is_invoice"
                                       className="checkbox_agreement formValidationItem checkbox"
                                       onChange={handleOnChangeIsInvoice}
                                />
                                <span className="checkmark"></span>
                                <span className="text">Chcę otrzymać fakturę</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div id="invoiceData" style="display:none">
                    <div className="checkout--separator"></div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="input--radio--component">
                                <label htmlFor="cart_invoice_type" className="radio--label -invoice">
                                    <span className="text">Faktura firma</span>
                                    <input type="radio"
                                           name="cart_invoice_type"
                                           className="input formValidationItem"
                                           onChange={(e) => handleOnChangeInvoiceType(e.target.value)}
                                           value="COMPANY"
                                           checked={true}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="input--radio--component">
                                <label htmlFor="cart_invoice_type" className="radio--label -invoice">
                                    <span className="text">Faktura osoba fizyczna</span>
                                    <input type="radio"
                                           name="cart_invoice_type"
                                           className="input formValidationItem"
                                           onChange={(e) => handleOnChangeInvoiceType(e.target.value)}
                                           value="PERSONAL"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <h4 className="header--h4 -margined -uppercase -buyerdata">Dane do faktury</h4>

                    <div className="row">
                        <div className="col-md-12 personalInvoice" style="display:none">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input--text--component">
                                        <label htmlFor="firstname" className="label">Imię
                                            <span className="required--component">*</span>
                                        </label>
                                        <div className="input--wrapper">
                                            <input type="text"
                                                   id=""
                                                   name="firstname"
                                                   placeholder="Imię"
                                                   className="input -capitalize nameDuplicated"
                                                   disabled
                                                // onChange={(event) => {onChangeInputValue(event)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input--text--component">
                                        <label htmlFor="cart_user_surname" className="label">Nazwisko<span className="required--component">*</span></label>
                                        <div className="input--wrapper">
                                            <input type="text"
                                                   placeholder="Nazwisko"
                                                   className="input -capitalize surnameDuplicated"
                                                   disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 companyInvoice">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input--text--component">
                                        <label htmlFor="cart_user_name" className="label">Nazwa firmy
                                            {/*<span className="required--component">*</span>*/}
                                        </label>
                                        <div className="input--wrapper">
                                            <input type="text"
                                                   id="companyNameInput"
                                                   name="cart_invoice_company_name"
                                                   placeholder="Nazwa firmy"
                                                   className="input formValidationItem"
                                                   data-name="companyName"
                                                   data-api-name="companyName"
                                                   data-api-field="invoiceAddress"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 invoiceCompanyNip">
                                    <div className="input--text--component">
                                        <label htmlFor="cart_user_company_nip" className="label">NIP
                                            {/*<span className="required--component">*</span>*/}
                                        </label>
                                        <div className="input--wrapper">
                                            <input type="text"
                                                   id="companyNipInput"
                                                   name="cart_user_company_nip"
                                                   placeholder="NIP"
                                                   className="input formValidationItem"
                                                   data-name="nip"
                                                   data-api-name="nip"
                                                   data-api-field="invoiceAddress"
                                                   data-api-field-group="nip"
                                                   onFocusOut={(event) => {console.log('a')}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_street_name" className="label">Ulica
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_street_name"
                                           placeholder="Ulica"
                                           className="input -capitalize formValidationItem"
                                           data-name="invoiceAddressStreet"
                                           data-api-field="invoiceAddress"
                                           data-api-name="streetName"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_street_name" className="label">Numer domu
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_street_name"
                                           placeholder="Numer domu"
                                           className="input formValidationItem"
                                           data-name="invoiceAddressHouseNumber"
                                           data-api-field="invoiceAddress"
                                           data-api-name="houseNumber"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_street_name" className="label">Numer mieszkania
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_street_name"
                                           placeholder="Numer mieszkania"
                                           className="input formValidationItem"
                                           data-name="invoiceAddressFlatNumber"
                                           data-api-field="invoiceAddress"
                                           data-api-name="flatNumber"
                                           onChange={(event) => {onChangeInvoiceFlatValue(event)}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_postcode" className="label">Kod pocztowy
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_postcode"
                                           placeholder="Kod pocztowy"
                                           className="input formValidationItem"
                                           data-name="invoicePostcode"
                                           data-api-field="invoiceAddress"
                                           data-api-name="postalCode"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_city" className="label">Miasto
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_city"
                                           placeholder="Miasto"
                                           className="input -capitalize formValidationItem"
                                           data-name="invoiceCity"
                                           data-api-field="invoiceAddress"
                                           data-api-name="city"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input--text--component">
                                <label htmlFor="cart_user_company_country" className="label">Kraj
                                    {/*<span className="required--component">*</span>*/}
                                </label>
                                <div className="input--wrapper">
                                    <input type="text"
                                           name="cart_user_company_country"
                                           placeholder="Kraj"
                                           className="input -capitalize formValidationItem"
                                           data-name="invoiceCountry"
                                           data-api-field="invoiceAddress"
                                           data-api-name="country"
                                        // disabled={true}
                                        // value="POLSKA"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="checkout--separator"></div>
                <div className="checkout--products--list">
                    <h4 className="title">Lista produktów</h4>
                    <div id="renderedCheckoutProducts">

                    </div>
                </div>

                <div className="inpost--form--data">
                    <div id="courierDataContainer">
                        <h4 className="header--h4 mb-md-3 -uppercase -margined -buyerdata">Dane dostawy - kurier</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="firstname" className="label">Imię
                                        <span className="required--component">*</span>
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="firstname"
                                               placeholder="Imię"
                                               className="input -capitalize nameDuplicated"
                                               data-name="userDeliveryName"
                                               disabled
                                            // onChange={(event) => {onChangeInputValue(event)}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_surname" className="label">Nazwisko<span className="required--component">*</span></label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_surname"
                                               placeholder="Nazwisko"
                                               className="input -capitalize surnameDuplicated"
                                               data-name="userDeliverySurname"
                                               disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_name" className="label">Nazwa firmy
                                        {/*<span className="required--component">*</span>*/}
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_company_name"
                                               placeholder="Nazwa firmy"
                                               className="input formValidationItem"
                                               data-name="userCompanyName"
                                               data-api-field="shippingAddress"
                                               data-api-name="usercompanyName"
                                               data-courier-name="companyName"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address" className="label">Ulica
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address"
                                               placeholder="Ulica"
                                               className="input -capitalize formValidationItem"
                                               data-name="userAddressStreetName"
                                               data-api-field="shippingAddress"
                                               data-api-name="streetName"
                                               data-courier-name="streetName"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address" className="label">Numer domu
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address"
                                               placeholder="Numer domu"
                                               className="input formValidationItem"
                                               data-name="userAddressHouseNumber"
                                               data-api-field="shippingAddress"
                                               data-api-name="houseNumber"
                                               data-courier-name="houseNumber"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address" className="label">Numer mieszkania
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address"
                                               placeholder="Numer mieszkania"
                                               className="input formValidationItem"
                                               data-name="userAddressFlatNumber"
                                               data-api-field="shippingAddress"
                                               data-api-name="flatNumber"
                                               data-courier-name="flatNumber"
                                               onChange={(event) => {onChangeFlatValue(event)}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address_postcode" className="label">Kod pocztowy
                                        {/*<span className="required--component">*</span>*/}
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address_postcode"
                                               placeholder="Kod pocztowy"
                                               className="input formValidationItem"
                                               data-name="userPostcode"
                                               data-api-field="shippingAddress"
                                               data-api-name="postalCode"
                                               data-courier-name="postalCode"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address_city" className="label">Miasto
                                        {/*<span className="required--component">*</span>*/}
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address_city"
                                               placeholder="Miasto"
                                               className="input -capitalize formValidationItem"
                                               data-name="userCity"
                                               data-api-field="shippingAddress"
                                               data-api-name="city"
                                               data-courier-name="city"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input--text--component">
                                    <label htmlFor="cart_user_address_city" className="label">Kraj
                                        {/*<span className="required--component">*</span>*/}
                                    </label>
                                    <div className="input--wrapper">
                                        <input type="text"
                                               name="cart_user_address_country"
                                               placeholder="Polska"
                                               className="input -capitalize formValidationItem"
                                            // value="Polska"
                                            // disabled={true}
                                               data-name="userCountry"
                                               data-api-field="shippingAddress"
                                               data-api-name="country"
                                               data-courier-name="country"
                                        />
                                    </div>
                                </div>
                            </div>


                            {/* <div className="col-md-12">
                                <div className="input--text--component mb-0">
                                    <label htmlFor="cart_user_additional_text" className="label">Dodatkowe uwagi
                                    </label>
                                    <div className="input--wrapper">
                                <textarea
                                    name="cart_user_additional_text"
                                    placeholder="Dodatkowe uwagi"
                                    className="input -textarea formValidationItem"
                                    data-api-name="remarks"
                                    data-api-field-group="remarks"
                                    data-api-field="contact"
                                    rows={3}>
                                </textarea>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>


                    <div id="deliveryInpostMapContainer"></div>
                </div>
            </div>

            <div className="summary">
                {CheckoutSumupBox(1)}
            </div>
        </div>
    )
}
export default CheckoutStepForm;
