import {createElement} from "../createElement";

import ProductDetail from "./ProductDetail";
import {tools} from "../tools";
import HeaderCart from "../Cart/HeaderCart";
import arrowSrc from '../assets/i/arrow.png';
import appGlobals from "../globals";
import appConfig from "../config";
import minusIconSrc from "../assets/i/icon-minus.png";
import iconCloseSrc from "../assets/i/btn-close.png";
import CloseButton from "../Common/CloseButton";

const handleOnClickBackButton = () => {
    tools.setActiveProductsListLayer();
};

const ProductDetailContent = (product) => {
    let hasBackArrow = true;
    let widgetProducts;
    if(appGlobals.blockApiCalls){
         widgetProducts = appGlobals.previewCarouselProducts.products;
        // hasBackArrow = false;
         // appGlobals.previewCarouselProducts.products;
    }else{
         widgetProducts = (appGlobals.activeWidgetIndex >= 0 && appGlobals.widgets.widgets[appGlobals.activeWidgetIndex]) ? appGlobals.widgets.widgets[appGlobals.activeWidgetIndex].products : null;
    }

    let foundProduct = widgetProducts ? widgetProducts.find(x => x.id === product.id) : null;
    if(foundProduct && foundProduct.position && foundProduct.position.x && foundProduct.position.y){
        hasBackArrow = false;
    }


    return (
        <div id="productDetailLayer" className="single--layer-app productDetailLayer">
            <div className="app--header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="app--header--title d-flex align-items-center">
                        {hasBackArrow ? (
                            <button type="button" className="btn--back" onClick={handleOnClickBackButton}><img src={appConfig.scriptDomainUrl+arrowSrc} className="img-fluid" /></button>
                        ): ''}
                        <h2 className="header--h2" id="productDetailHeader">Nazwa kolekcji - opcjonalnie </h2>
                    </div>
                    <div className="d-flex">
                        <div className="app--header--cart">
                            {HeaderCart}
                            <div id="cartLastProductBox" className="header--cart--last--product--box"></div>
                        </div>
                        <CloseButton/>
                    </div>
                </div>
            </div>
            <div className="modalcontent modal--content">
                <ProductDetail product={product}/>
            </div>
        </div>
    )
};

export default ProductDetailContent;